import { CTYPES, TYPES } from '../components/filters/type_filter_panel'
import {
  fetchWithHeaders,
  getQueryParams,
  parseResponse,
  responseData,
} from '../shared/utils'
import { KEYS } from '../components/filters/key_filter_panel'
import { MAX_BPM, MIN_BPM } from '../components/filters'
import { REGION_LOCALES } from '../../locale_data/config'
import { SORT_OPTIONS } from '../components/filters/sort_panel'
import PropTypes from 'prop-types'

export const standardFiltersShape = {
  genres: PropTypes.number,
  locale: PropTypes.oneOf(Object.keys(REGION_LOCALES)),
  types: PropTypes.oneOf(Object.values(TYPES)),
  ctypes: PropTypes.oneOf(Object.values(CTYPES)),
  bpmgt: PropTypes.number,
  bpmlt: PropTypes.number,
  keys: PropTypes.arrayOf(PropTypes.oneOf(KEYS)),
}

export const FiltersPropType = PropTypes.shape({
  ...standardFiltersShape,
})

export const PaginationPropType = PropTypes.shape({
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
})

export const SortByPropType = PropTypes.number

export const TrackPropType = PropTypes.shape({
  tid: PropTypes.number,
  ttid: PropTypes.number,
  type: PropTypes.string,
  trackType: PropTypes.string,
  rid: PropTypes.number,
  trackLength: PropTypes.number,
  // previouslyDownloaded: PropTypes.number,
})

export const TagPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

export const ChangePropType = PropTypes.shape({
  change: PropTypes.string.isRequired,
  date: PropTypes.string,
  ttid: PropTypes.number.isRequired,
  tid: PropTypes.number.isRequired,
})

export const SongPropType = PropTypes.shape({
  rid: PropTypes.number,
  artist: PropTypes.string,
  title: PropTypes.string,
  featuring: PropTypes.string,
  bpm: PropTypes.number,
  key: PropTypes.string,
  category: PropTypes.string,
  meta: PropTypes.shape({
    averageRating: PropTypes.number,
    myRating: PropTypes.number,
  }),
  previewUrl: PropTypes.string,
  genres: PropTypes.arrayOf(TagPropType),
  types: PropTypes.arrayOf(TrackPropType),
  changes: PropTypes.arrayOf(ChangePropType),
})

export const DownloadPropType = PropTypes.shape({
  id: PropTypes.number,
  downloadCount: PropTypes.number,
  record: PropTypes.shape({
    artist: PropTypes.string,
    rid: PropTypes.string,
    title: PropTypes.string,
    types: PropTypes.arrayOf(TagPropType),
  }),
  trackType: PropTypes.string.isRequired,
  // ttid: PropTypes.number,
})

export const defaultPagination = {
  page: 1,
  pageSize: 15,
  pageCount: 1,
}

export const defaultHotBoxPagination = {
  page: 1,
  pageSize: 30,
  pageCount: 1,
}

export const defaultRemixersPagination = {
  page: 1,
  pageSize: 100,
  pageCount: 1,
}

export const defaultGenresPagination = {
  page: 1,
  pageSize: 30,
  pageCount: 1,
}

export const defaultUpdatedPagination = {
  page: 1,
  pageSize: 30,
  pageCount: 1,
}

export const defaultUpdatedParams = {
  page: 1,
  pageSize: 30,
  myTracks: false,
}

export const defaultRemixersParams = {
  name: '',
  sortBy: SORT_OPTIONS.TITLE_ASC,
  onlyFeatured: true,
}

export const defaultTopDownloadsPagination = {
  page: 1,
  pageSize: 50,
  pageCount: 1,
}

export const defaultGlobalPagination = {
  page: 1,
  pageSize: 30,
  pageCount: 1,
}

export const defaultNewReleasesPagination = {
  page: 1,
  pageSize: 30,
  pageCount: 1,
}

export const defaultExclusivesPagination = {
  page: 1,
  pageSize: 50,
  pageCount: 1,
}

export const defaultDownloadHistoryPagination = {
  page: 1,
  pageSize: 50,
}

export const defaultFilters = {
  locale: 'en-US',
  genres: [],
  types: [],
  ctypes: [],
  bpmgt: MIN_BPM,
  bpmlt: MAX_BPM,
  remixers: [],
  keys: [],
  regionId: 0,
}

const safeParse = x => parseInt(x, 10) || 0

export const filterParsers = {
  genres: t => t.split(',').map(safeParse),
  types: t => [safeParse(t)],
  ctypes: t => [safeParse(t)],
  bpmgt: safeParse,
  bpmlt: safeParse,
  remixers: r => r.split(',').map(safeParse),
  keys: k => k.split(','),
  regionId: safeParse,
  page: safeParse,
  pageSize: safeParse,
  sortBy: safeParse,
}

export const filterStringifiers = {
  genres: t => (t ? t.join(',') : ''),
  types: t => (t ? t.join(',') : ''),
  ctypes: t => (t ? t.join(',') : ''),
  remixers: r => (r ? r.join(',') : ''),
  keys: k => (k ? k.join(',') : ''),
}

export const defaultHotBoxFilters = {
  ...defaultFilters,
  type: '1W',
  regionId: 0,
}

export const defaultGlobalFilters = {
  ...defaultFilters,
  genres: [3],
  regionId: 0,
  sortBy: SORT_OPTIONS.DATE_DESC,
}

const today = new Date()

// Top Downloads for each month
// should be available the prior month at on the 26th, midnight PST
export const isMonthTurnWindow = () => {
  const releaseDate = new Date()
  releaseDate.setMonth(today.getMonth())
  releaseDate.setDate(26)
  releaseDate.setFullYear(today.getFullYear())
  releaseDate.setHours(2, 0, 0, 0)

  const relaseDateOnPST = new Date(
    releaseDate.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })
  )

  const todayOnPST = new Date(
    today.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })
  )

  return todayOnPST > relaseDateOnPST
}

const getTopDownloadLatestMonth = () => {
  if (isMonthTurnWindow()) {
    return today.getMonth() + 1
  }
  return today.getMonth()
}

export const defaultTopDownloadsFilters = {
  ...defaultFilters,
  month: getTopDownloadLatestMonth(),
  year: today.getFullYear(),
}

export const defaultPlaylistsFilters = {
  ...defaultFilters,
  month: today.getMonth() + 1,
  year: today.getFullYear(),
}

export const defaultSortBy = SORT_OPTIONS.DATE_DESC

export const searchDefaultSortBy = SORT_OPTIONS.RELEVANCE

export function getAll(params) {
  return fetchWithHeaders(`/v1/songs?${getQueryParams(params)}`, {
    method: 'GET',
  }).then(responseData)
}

export function getNew(params) {
  return fetchWithHeaders(`/v1/songs/new?${getQueryParams(params)}`, {
    method: 'GET',
  }).then(responseData)
}

export function getExclusives(params) {
  return fetchWithHeaders(`/v1/songs/exclusives?${getQueryParams(params)}`, {
    method: 'GET',
  }).then(responseData)
}

export function getUpdated(params) {
  const queryParams = { ...defaultUpdatedPagination, ...params }
  return fetchWithHeaders(`/v1/songs/updated?${getQueryParams(queryParams)}`, {
    method: 'GET',
  }).then(responseData)
}

export function statistics(guid) {
  return fetchWithHeaders(`/v1/songs/statistics/${guid}`, {
    method: 'GET',
  }).then(responseData)
}

export function details(id) {
  return fetchWithHeaders(`/v1/songs/${id}`, {
    method: 'GET',
  }).then(responseData)
}

export function search(params) {
  return fetchWithHeaders(`/v1/esearch?${getQueryParams(params)}`, {
    method: 'GET',
  }).then(responseData)
}

export function autocomplete(searchTerm) {
  return fetchWithHeaders(
    `/v1/search/autocomplete?${getQueryParams({ searchTerm })}`,
    {
      method: 'GET',
    }
  ).then(responseData)
}

export function hotBox(params) {
  return fetchWithHeaders(`/v1/songs/hotbox?${getQueryParams(params)}`, {
    method: 'GET',
  }).then(responseData)
}

export function topDownloads(params) {
  return fetchWithHeaders(`/v1/songs/top-downloads?${getQueryParams(params)}`, {
    method: 'GET',
  }).then(responseData)
}

export async function playlists(params) {
  const res = await fetchWithHeaders(
    `/v1/songs/trends?${getQueryParams(params)}`,
    {
      method: 'GET',
    }
  )
  return responseData(res)
}

/**
 * Retrieves available month/year for released playlists
 * @returns {Promise<Response>}
 */
export async function availablePlaylists() {
  const res = await fetchWithHeaders('/trends/dates', {
    method: 'GET',
  })
  return responseData(res)
}

/**
 * Retrieves available generic playlist names
 * @returns {Promise<Response>}
 */
export async function getGenericPlaylists() {
  const res = await fetchWithHeaders('/trends/generic-playlists', {
    method: 'GET',
  })
  return responseData(res)
}

export function tag(id) {
  return fetchWithHeaders(`/tags/${id}`, {
    method: 'GET',
  }).then(responseData)
}

export function remixer(id) {
  return fetchWithHeaders(`/remixers/${id}?strict=true`, {
    method: 'GET',
  })
    .then(responseData)
    .then(data => data.remixer)
}

export function remixers(namePrefix) {
  return fetchWithHeaders(`/remixers?${getQueryParams({ name: namePrefix })}`, {
    method: 'GET',
  })
    .then(responseData)
    .then(data => data.remixers)
}

export function getAllremixers(params) {
  return fetchWithHeaders(`/all-remixers?${getQueryParams(params)}`, {
    method: 'GET',
  }).then(responseData)
}

export function tags() {
  return fetchWithHeaders(
    `/tags?popularity=${process.env.GENRE_FILTER_MIN_SONGS_COUNT}`,
    {
      method: 'GET',
    }
  )
    .then(responseData)
    .then(data => data.tags)
}

/**
 * Mark array of songs as played
 * @param rids
 * @returns {Promise<Response>}
 */
export function played(rids) {
  return fetchWithHeaders(`/v1/songs/played`, {
    method: 'POST',
    body: JSON.stringify({
      rids,
    }),
  }).then(parseResponse)
}

export async function availableTopDownloads() {
  const res = await fetchWithHeaders('/v1/songs/top-downloads/dates', {
    method: 'GET',
  })
  return responseData(res)
}
