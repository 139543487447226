import * as stringResourcesActions from '../../action_creators/string_resources'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { defaultStringResources } from '../../shared/utils'
import { fetchAvailableMaintenance } from '../../reducers/maintenance'
import { Loading } from '../loading'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

class BlankLayout extends React.Component {
  constructor(props) {
    super(props)
    this.fetchResources = this.fetchResources.bind(this)
  }

  componentDidMount() {
    const { fetchAvailableMaintenance } = this.props
    fetchAvailableMaintenance()
    this.fetchResources()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.locale !== prevProps.locale ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.fetchResources()
    }
  }

  fetchResources() {
    const { requiredResources, fetchStringResources } = this.props
    fetchStringResources(requiredResources)
  }

  render() {
    const { children, isFetching } = this.props
    return isFetching ? <Loading /> : children
  }
}

function mapStateToProps(state) {
  return {
    locale: state.locale.locale,
    isFetching: state.stringResources.isFetching,
  }
}

function mapDispatchToProps(dispatch) {
  const { fetchStringResources } = bindActionCreators(
    stringResourcesActions,
    dispatch
  )
  return {
    fetchStringResources,
    fetchAvailableMaintenance,
  }
}

BlankLayout.propTypes = {
  children: PropTypes.node,
  fetchAvailableMaintenance: PropTypes.func.isRequired,
  fetchStringResources: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isFetching: PropTypes.bool,
  locale: PropTypes.string,
  location: PropTypes.object.isRequired,
  requiredResources: PropTypes.arrayOf(PropTypes.string),
}

BlankLayout.defaultProps = {
  requiredResources: defaultStringResources,
}

const ConnectedBlankLayout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BlankLayout)
)

export { ConnectedBlankLayout as BlankLayout }
