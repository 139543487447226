import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { loggedInSelector } from 'spa/selectors/users'
import {
  Navigation,
  NavMenu,
  NavMenuContent,
  NavMenuContentItem,
} from 'spa/components/navigation'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles'

const BROWSE_MENU_ID = 'BrowseNavMenu'
const MORE_MENU_ID = 'MoreNavMenu'
const NEWS_MENU_ID = 'NewsNavMenu'

const localizedMessages = defineMessages({
  browseTitle: {
    id: 'djcity.common.navigation.browse',
    defaultMessage: 'Record Pool',
  },
  moreTitle: {
    id: 'djcity.common.navigation.more',
    defaultMessage: 'More',
  },
  newsTitle: {
    id: 'djcity.common.navigation.news',
    defaultMessage: 'News',
  },
  login: {
    id: 'djcity.common.navigation.login',
    defaultMessage: 'Login',
  },
  darkMode: {
    id: 'djcity.common.navigation.darkMode',
    defaultMessage: 'Dark Mode',
  },
  off: {
    id: 'djcity.common.navigation.off',
    defaultMessage: 'Off',
  },
  on: {
    id: 'djcity.common.navigation.on',
    defaultMessage: 'On',
  },
  faqUrl: {
    id: 'djcity.common.faq.url',
    defaultMessage: 'https://support.djcity.com/hc',
  },
})

const NavigationMenu = ({ intl }) => {
  const isLoggedIn = useSelector(loggedInSelector)
  const history = useHistory()
  const handleNavigate = useCallback(
    href => {
      if (history) {
        history.push(href)
      }
    },
    [history]
  )

  return (
    <Navigation className={styles.navigation}>
      <NavMenu
        id={BROWSE_MENU_ID}
        title={intl.formatMessage(localizedMessages.browseTitle)}
      >
        <NavMenuContent>
          <NavMenuContentItem
            href="/"
            menuId={BROWSE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="Home"
              id="djcity.common.navigation.home"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="/new-releases"
            menuId={BROWSE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="New Releases"
              id="djcity.common.navigation.new_releases"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="/most-popular"
            menuId={BROWSE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="Most Popular"
              id="djcity.common.navigation.most_popular"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="/exclusives"
            menuId={BROWSE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="DJcity Exclusives"
              id="djcity.common.navigation.djcity_exclusives"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="/playlists/monthly"
            menuId={BROWSE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="Playlists"
              id="djcity.common.navigation.playlists"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="/monthly-charts"
            menuId={BROWSE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="Monthly Charts"
              id="djcity.common.navigation.monthly_top_50"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="/genres"
            menuId={BROWSE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="Genres"
              id="djcity.common.navigation.genres"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="/remixers"
            menuId={BROWSE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="Remixers"
              id="djcity.common.navigation.remixers"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="/track-updates"
            menuId={BROWSE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="Track Updates"
              id="djcity.common.navigation.trackUpdates"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="/global"
            menuId={BROWSE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="Global"
              id="djcity.common.navigation.global_songs"
            />
          </NavMenuContentItem>
        </NavMenuContent>
      </NavMenu>
      <NavMenu
        id={MORE_MENU_ID}
        title={intl.formatMessage(localizedMessages.moreTitle)}
      >
        <NavMenuContent>
          <NavMenuContentItem
            href="/apps"
            menuId={MORE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="Apps"
              id="djcity.common.navigation.apps"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="https://www.youtube.com/tvdjcity"
            menuId={MORE_MENU_ID}
            target="_blank"
          >
            <FormattedMessage
              defaultMessage="DJcityTV"
              id="djcity.common.navigation.djcity.tv"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href={intl.formatMessage(localizedMessages.faqUrl)}
            menuId={MORE_MENU_ID}
            target="_blank"
          >
            <FormattedMessage
              defaultMessage="FAQ"
              id="djcity.common.navigation.faq"
            />
          </NavMenuContentItem>
          {isLoggedIn && (
            <NavMenuContentItem
              href="/account"
              menuId={MORE_MENU_ID}
              navigateTo={handleNavigate}
            >
              <FormattedMessage
                defaultMessage="Your Account"
                id="djcity.common.navigation.yourAccount"
              />
            </NavMenuContentItem>
          )}

          <NavMenuContentItem
            href="/contact-us"
            menuId={MORE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="Contact Us"
              id="djcity.common.navigation.contact_us"
            />
          </NavMenuContentItem>
          <NavMenuContentItem
            href="/about"
            menuId={MORE_MENU_ID}
            navigateTo={handleNavigate}
          >
            <FormattedMessage
              defaultMessage="About the Platform "
              id="djcity.common.navigation.about"
            />
          </NavMenuContentItem>
        </NavMenuContent>
      </NavMenu>
      <NavMenu
        disableChildMenu
        id={NEWS_MENU_ID}
        title={intl.formatMessage(localizedMessages.newsTitle)}
      >
        <NavMenuContentItem
          href="/news"
          menuId={MORE_MENU_ID}
          navigateTo={handleNavigate}
        >
          <FormattedMessage
            defaultMessage="News"
            id="djcity.common.navigation.news"
          />
        </NavMenuContentItem>
      </NavMenu>
    </Navigation>
  )
}

NavigationMenu.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

const ConnectedNavigation = injectIntl(NavigationMenu)

export { ConnectedNavigation as NavigationMenu }

export default NavigationMenu
