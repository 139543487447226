import { RECEIVE_STATISTICS, REQUEST_STATISTICS } from '../action_constants'

export default function statistics(
  state = {
    isLoading: false,
    data: {
      numericalStats: {
        recordsDownloaded: 0,
        remixes: 0,
        originals: 0,
        exclusives: 0,
        hours: 0,
        genresDownloaded: 0,
        averageBpm: 0,
        downloadStreakDays: 0,
      },
      topArtists: [],
      topGenres: [],
      topTools: [],
      topRemixers: [],
      recommendedRecordsByDownloadedGenres: [],
      mostPopularRecordsByMostDownloadedMainGenre: [],
      mostDownloadedRecordsByDownloadedRemixers: [],
    },
  },
  action
) {
  switch (action.type) {
    case REQUEST_STATISTICS:
      return {
        ...state,
        isLoading: true,
      }
    case RECEIVE_STATISTICS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      }
    default:
      return state
  }
}
