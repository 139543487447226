import {
  CLEAR_CURRENT_USER,
  ERROR_RECEIVING_VIP_CODE,
  RECEIVE_PAYMENT_ERROR,
  RECEIVE_PAYMENT_METHOD_CHANGE_ERROR,
  RECEIVE_PAYMENT_METHOD_CHANGE_SUCCESS,
  RECEIVE_PAYMENT_SUCCESS,
  RECEIVE_VIP_CODE,
  REQUEST_PAYMENT,
  REQUEST_PAYMENT_METHOD_CHANGE,
  REQUEST_VIP_CODE,
  RESET_CHECKOUT_ERRORS,
} from '../action_constants'

const defaultState = {
  country: '',
  supported: [],
  paymentConfiguration: {},
  transactions: [],
  isFetching: false,
  isSaving: false,
  isProcessing: false,
  error: null,
}

export default function currentUserBilling(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_CURRENT_USER:
      return {
        ...state,
        error: null,
      }
    case REQUEST_VIP_CODE:
    case REQUEST_PAYMENT:
    case REQUEST_PAYMENT_METHOD_CHANGE:
      return {
        ...state,
        isProcessing: true,
        error: null,
      }
    case RECEIVE_VIP_CODE:
    case RECEIVE_PAYMENT_SUCCESS:
    case RECEIVE_PAYMENT_METHOD_CHANGE_SUCCESS:
      return {
        ...state,
        error: null,
        isProcessing: false,
      }
    case ERROR_RECEIVING_VIP_CODE:
    case RECEIVE_PAYMENT_ERROR:
    case RECEIVE_PAYMENT_METHOD_CHANGE_ERROR:
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      }
    case RESET_CHECKOUT_ERRORS:
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}
