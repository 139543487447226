import { defineMessages } from 'react-intl'
import { getAuthToken } from '../reducers/current_user'
import { intl } from './IntlGlobalProvider'
import camelCase from 'lodash/camelCase'
import each from 'lodash/each'
import isPlainObject from 'lodash/isPlainObject'
import snakeCase from 'lodash/snakeCase'

export function generateQueryParams({ locale, queryParams }) {
  return {
    filters: {
      locale: locale || queryParams.locale,
      genres: queryParams.genres,
      types: queryParams.types,
      ctypes: queryParams.ctypes,
      bpmgt: queryParams.bpmgt,
      bpmlt: queryParams.bpmlt,
      remixers: queryParams.remixers,
      keys: queryParams.keys,
      regionId: queryParams.regionId,
      type: queryParams.type,
    },
    pagination: {
      page: queryParams.page,
      pageSize: queryParams.pageSize,
    },
    sortBy: queryParams.sortBy,
  }
}

export function convertToSnakeCase(hash) {
  let snakeHash = {}
  each(hash, (v, k) => {
    isPlainObject(v)
      ? (snakeHash[snakeCase(k)] = convertToSnakeCase(v))
      : (snakeHash[snakeCase(k)] = v)
  })
  return snakeHash
}

export function convertToCamelCase(hash) {
  let camelHash = {}
  each(hash, (v, k) => {
    isPlainObject(v)
      ? (camelHash[camelCase(k)] = convertToCamelCase(v))
      : (camelHash[camelCase(k)] = v)
  })
  return camelHash
}

export function formatTime(seconds) {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)
  return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
    .filter(a => a)
    .join(':')
}

export function number(num) {
  let answer = Number(num)
  if (isNaN(answer)) {
    return 0
  } else {
    return answer
  }
}

export function filterInt(value) {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value)
  } else {
    return NaN
  }
}

export const northAmericaStates = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const northAmericaStateCodes = {}
Object.keys(northAmericaStates).forEach(key => {
  northAmericaStateCodes[northAmericaStates[key]] = key
})

export const orderByOptions = [
  { field: 'releasedate', mode: 'desc' },
  { field: 'releasedate', mode: 'asc' },
  { field: 'artist', mode: 'asc' },
  { field: 'artist', mode: 'desc' },
  { field: 'title', mode: 'asc' },
  { field: 'title', mode: 'desc' },
  { field: 'bpm', mode: 'asc' },
  { field: 'bpm', mode: 'desc' },
  { field: 'popularity', mode: 'desc' },
]

export const AUTO_DISMISS_SECONDS = 3
export const LONG_AUTO_DISMISS_SECONDS = 6

// Processors
export const PAYMENT_PROCESSORS = {
  STRIPE: 'STRIPE',
  BRAINTREE: 'BRAINTREE',
  PAYPAL: 'PAYPAL',
  PAYPALUS: 'PAYPALUS',
}

export const UNSUPPORTED_PROCESSORS = [PAYMENT_PROCESSORS.PAYPALUS]

export function calculateDaysPast(date) {
  const diffTime = Math.abs(Date.now() - date)
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export function formatKey(key) {
  return `djcity.${key}`
}

export function saveToLocalStorage(key, obj) {
  try {
    const serializedObject = JSON.stringify(obj)
    localStorage.setItem(formatKey(key), serializedObject)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Unable to store', key, error)
  }
}

export function loadFromLocalStorage(key) {
  try {
    const serializedObject = localStorage.getItem(formatKey(key))
    if (serializedObject === null) {
      return undefined
    }

    return JSON.parse(serializedObject)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Unable to load', key, error)
  }
}

export function removeFromLocalStorage(key) {
  try {
    localStorage.removeItem(formatKey(key))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Unable to remove', key, error)
  }
}

export async function fetchWithHeaders(route, requestOptions) {
  const { token } = getAuthToken()
  const headers = new Headers()
  headers.append('Authorization', token ? `JWT ${token}` : '')
  headers.append('Content-Type', 'application/json')
  headers.append('x-djc-app-id', process.env.APP_ID)
  headers.append('x-djc-app-version', '4.0.0')
  if (process.env.NODE_ENV === 'development' && process.env.CLIENT_IP) {
    headers.append('x-forwarded-for', process.env.CLIENT_IP)
    headers.append('x-postmark-disabled', process.env.POSTMARK_DISABLED)
  }
  requestOptions.headers = headers
  requestOptions.credentials = 'include'
  const fullUrl = `${process.env.API_URL}${route}`
  return fetch(fullUrl, requestOptions)
}

const portParams = {
  genres: 'tags',
}

export const getQueryParams = params => {
  let actualKey
  return Object.keys(params)
    .filter(key => params[key])
    .map(key => {
      if (portParams[key]) actualKey = portParams[key]
      else actualKey = key

      if (typeof params[key] === 'string') {
        return actualKey + '=' + encodeURIComponent(params[key].trim())
      }
      return actualKey + '=' + encodeURIComponent(params[key])
    })
    .join('&')
}

export const parseResponse = async response => {
  try {
    const contentType = response.headers.get('Content-Type')
    const contentJson = contentType && contentType.includes('application/json')

    const content = await (contentJson ? response.json() : Promise.resolve({}))
    return {
      data: content,
      status: response.status,
      statusText: response.statusText,
      statusCode: response.statusCode,
      ok: response.ok,
    }
  } catch (ex) {
    throw parseErrorResponse(response, ex)
  }
}

export const responseData = response => {
  return parseResponse(response).then(res => res.data)
}

function parseErrorResponse(response, content) {
  const userDescription =
    content.userDescription ||
    'Unable to process your request. Please try again later'

  return {
    status: response.status,
    statusText: response.statusText,
    statusCode: response.statusCode,
    ok: response.ok,
    userDescription,
  }
}

export const MAX_DOWNLOAD_COUNT = 3

export function findAllIndexes(text, pattern) {
  const result = []
  if (!text || !pattern) {
    return result
  }

  let index = -1
  do {
    index = text.indexOf(pattern, index + 1)
    if (index >= 0) {
      result.push(index)
    }
  } while (index >= 0)

  return result
}

export const DEFAULT_DATE_OPTION = {
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  timeZone: 'GMT',
}

export const DEFAULT_SHORT_DATE_OPTION = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timeZone: 'GMT',
}

export const PROMOS = {
  FDP: 'FDP',
  WINBACK: 'WINBACK',
  WINBACK4PLUS2: 'WINBACK4PLUS2',
  NETNEW: 'NETNEW',
  SERATO: 'SERATO',
  REGULAR: 'REGULAR',
  TRIAL: 'TRIAL',
  VIP: 'VIP',
  DJCITY2025: 'DJCITY2025',
  DJCITY2025M12: 'DJCITY2025M12',
}

export function getFutureDate(promoData) {
  const today = new Date()

  if (!promoData) return today

  const promoEndDate = today
  switch (promoData.durationType) {
    case 'D':
      promoEndDate.setDate(today.getDate() + promoData.duration)
      break
    case 'W':
      promoEndDate.setDate(today.getDate() + promoData.duration * 7)
      break
    case 'M':
      promoEndDate.setMonth(today.getMonth() + promoData.duration)
      break
    case 'Y':
      promoEndDate.setFullYear(today.getFullYear() + promoData.duration)
      break
    default:
      break
  }

  return new Date(promoEndDate)
}

export const NOTIFICATION_MESSAGES = defineMessages({
  appNotInstalledTitle: {
    id: 'djcity.common.download.queue.title',
    defaultMessage: 'Desktop App Required',
  },
  appNotInstalledMessage: {
    id: 'djcity.common.download.queue.message',
    defaultMessage:
      'Install our desktop app to send tracks to downloader queue.',
  },
  batchError: {
    id: 'djcity.common.error.batch.message',
    defaultMessage:
      'You can not use the batch feature for this record as one or more of the versions have reached the download limit. Please add the missing tracks singularly.',
  },
  downloadInProgressError: {
    id: 'djcity.common.error.download_in_progress.message',
    defaultMessage:
      'You can not use the batch feature while a download is in progress.',
  },
  bulkDownloadDisabled: {
    id: 'djcity.common.error.bulk_download_disabled_for_user',
    defaultMessage:
      'The batch download feature is disabled for your account. Please use the Desktop app for batch downloads.',
  },
  bulkDownloadDisabledForSomeDevices: {
    id: 'djcity.common.error.bulk_download_disabled',
    defaultMessage:
      'Please use a non-iOS device or the Desktop app for batch downloads',
  },
  creditCard: {
    id: 'djcity.billing.payment.card.notification',
    defaultMessage: 'Credit Card',
  },
  invalidCardType: {
    id: 'djcity.billing.payment.invalid.brand',
    defaultMessage: '{brand} not an accepted payment type.',
  },
  subscribeLabel: {
    id: 'djcity.billing.subscribe.label',
    defaultMessage: 'Subscribe',
  },
  installLabel: {
    id: 'djcity.common.install.label',
    defaultMessage: 'Install',
  },
  noMemberTitle: {
    id: 'djcity.common.no.member.notification.title',
    defaultMessage: 'Sorry,',
  },
  noMemberMessage: {
    id: 'djcity.common.no.member.notification.message',
    defaultMessage: 'You must be a member to do that.',
  },
  yourCrate: {
    id: 'djcity.records.your_crate.notification.title',
    defaultMessage: 'Your Crate',
  },
  error: {
    id: 'djcity.common.error.notification.title',
    defaultMessage: 'Error',
  },
  cooldownError: {
    id: 'djcity.common.error.promos.cooldown',
    defaultMessage:
      "You cannot redeem this promo as you've already redeemed one on {lastRedeem}",
  },
  trackSentDownloader: {
    id: 'djcity.records.tracks.sent.downloader.notification.title',
    defaultMessage: 'Track sent to downloader!',
  },
  allTracksSentDownloader: {
    id: 'djcity.records.all.tracks.sent.downloader.notification.title',
    defaultMessage: 'All tracks sent to downloader!',
  },
  allTracksAlreadyInDownloader: {
    id: 'djcity.records.all.tracks.sent.downloader.notification.error',
    defaultMessage: 'All tracks were already sent to downloader!',
  },
  billingTitle: {
    id: 'djcity.common.notification.billing.title',
    defaultMessage: 'Billing',
  },
  paymentUpdated: {
    id: 'djcity.common.notification.billing.paymentupdated',
    defaultMessage: 'Your payment method has been updated',
  },
  paymentProcessed: {
    id: 'djcity.common.notification.billing.paymentprocessed',
    defaultMessage: 'Your payment has been processed!',
  },
  subscriptionCanceled: {
    id: 'djcity.common.notification.billing.subscriptioncanceled',
    defaultMessage: 'Your subscription has been set to cancel.',
  },
  subscriptionReactivated: {
    id: 'djcity.common.notification.billing.subscriptionreactivated',
    defaultMessage: 'Your subscription has been reactivated.',
  },
  subscriptionMissing: {
    id: 'djcity.common.notification.billing.subscriptionmissing',
    defaultMessage: 'Please select a plan to proceed to checkout',
  },
  checkoutTitle: {
    id: 'djcity.billing.checkout.notification.title',
    defaultMessage: 'Checkout',
  },
  trackAddedCrate: {
    id: 'djcity.common.crate.notification.message',
    defaultMessage: 'Track added to crate!',
  },
  tracksAddedCrate: {
    id: 'djcity.common.crate.tracks.notification.message',
    defaultMessage: 'Tracks added to crate!',
  },
  trackRemovedCrate: {
    id: 'djcity.common.removed.crate.notification.message',
    defaultMessage: 'Track removed from crate!',
  },
  tracksRemovedCrate: {
    id: 'djcity.common.crate.tracksremoved.notification.message',
    defaultMessage: 'Tracks removed from crate!',
  },
  undolabel: {
    id: 'djcity.common.undo.button.label',
    defaultMessage: 'Undo',
  },
  allTracksRemovedCrate: {
    id: 'djcity.common.all.removed.crate.notification.message',
    defaultMessage: 'All tracks have been removed!',
  },
  tracksQueued: {
    id: 'djcity.records.tracks.queued.notification.message',
    defaultMessage:
      'Your tracks have been queued in the Desktop App for download.',
  },
  duplicated: {
    id: 'djcity.records.tracks.duplicated.notification.message',
    defaultMessage:
      "There were some tracks that were duplicates that we've left in your Crate.",
  },
  downloadLimit: {
    id: 'djcity.records.tracks.download.limit.notification.message',
    defaultMessage:
      "There were some tracks that had maximum download limit exceeded that we've left in your Crate.",
  },
  shoppingCartTitle: {
    id: 'djcity.common.shopping.cart.notification.title',
    defaultMessage: 'Shopping Cart',
  },
  itemAddedToShoppingCart: {
    id: 'djcity.common.shopping.cart.notification.message',
    defaultMessage: 'Item added to Shopping Cart!',
  },
  shoppingCartError: {
    id: 'djcity.common.shopping.cart.error.notification.message',
    defaultMessage: 'There was an error with your Shopping Cart.',
  },
  itemDeletedFromShoppingCart: {
    id: 'djcity.common.deleted.shopping.cart.notification.message',
    defaultMessage: 'Item deleted from Shopping Cart!',
  },
  itemUpdateInShoppingCart: {
    id: 'djcity.common.updated.shopping.cart.notification.message',
    defaultMessage: 'Item updated in Shopping Cart!',
  },
  loadingProductsError: {
    id: 'djcity.common.loading.products.error.notification.message',
    defaultMessage: "There was an error loading store's products.",
  },
  trackDownloadTitle: {
    id: 'djcity.records.tracks.download.notification.title',
    defaultMessage: 'Track Download',
  },
  trackDownloadActionSuccess: {
    id: 'djcity.records.tracks.download.initiated.notification.message',
    defaultMessage: 'Download initiated!',
  },
  trackDownloadActionError: {
    id: 'djcity.records.tracks.download.error.notification.message',
    defaultMessage: 'Error!',
  },
  songDownloadTitle: {
    id: 'djcity.records.song.download.notification.title',
    defaultMessage: 'Song Download',
  },
  yourPreferencesTitle: {
    id: 'djcity.account.your_preferences.notification.title',
    defaultMessage: 'Your Preferences',
  },
  yourPreferencesActionSuccess: {
    id: 'djcity.account.your_preferences.success.notification.message',
    defaultMessage: 'Preferences updated!',
  },
  yourPreferencesActionError: {
    id: 'djcity.account.your_preferences.error.notification.message',
    defaultMessage: 'Error!',
  },
  accountActionSuccess: {
    id: 'djcity.account.update.success.notification.message',
    defaultMessage: 'Your information has been saved!',
  },
  accountActionError: {
    id: 'djcity.account.update.error.notification.message',
    defaultMessage:
      'Looks like we found some errors, please review your information',
  },
  playerTitle: {
    id: 'djcity.records.player.notification.title',
    defaultMessage: 'Player',
  },
  songAddedToPreviewQueue: {
    id: 'djcity.records.song.added.preview.queue.notification.message',
    defaultMessage: 'Song added to preview queue!',
  },
  songsAddedToPreviewQueue: {
    id: 'djcity.records.added.preview.queue.notification.message',
    defaultMessage: 'Songs added to preview queue!',
  },
  songRemovedFromPreviewQueue: {
    id: 'djcity.records.song.removed.preview.queue.notification.message',
    defaultMessage: 'Song removed from preview queue!',
  },
  accountTitle: {
    id: 'djcity.account.notification.title',
    defaultMessage: 'Account',
  },
  billingAddressTitle: {
    id: 'djcity.billing.address.notification.title',
    defaultMessage: 'Billing Address',
  },
  signupTitle: {
    id: 'djcity.account.signup.notification.title',
    defaultMessage: 'Signup',
  },
  forgotPasswordTitle: {
    id: 'djcity.account.password.notification.title',
    defaultMessage: 'Forgot Password',
  },
  changePasswordTitle: {
    id: 'djcity.account.change_password.notification.title',
    defaultMessage: 'Change Password',
  },
  loginTitle: {
    id: 'djcity.account.login.notification.title',
    defaultMessage: 'Login',
  },
  loginDeniedTitle: {
    id: 'djcity.account.login.notification.deniedTitle',
    defaultMessage: 'Access Denied',
  },
  loginDeniedBody: {
    id: 'djcity.account.error.login_atempt_threshold_exceeded',
    defaultMessage:
      'Too many failed login attempts. Please try again in 1 hour.',
  },
  loginError: {
    id: 'djcity.account.login.notification.error',
    defaultMessage:
      'Your password has expired. Please click button below to reset it.',
  },
  passwordError: {
    id: 'djcity.account.login.password.error',
    defaultMessage:
      'DJcity is currently down for maintenance.  Please try again in a couple hours.',
  },
  loginAction: {
    id: 'djcity.account.login.error.notification.action',
    defaultMessage: 'Reset Password',
  },
  updatedBillingAddress: {
    id: 'djcity.billing.address.update.notification.message',
    defaultMessage: 'You have successfully updated your billing address.',
  },
  notUpdatedBillingAddress: {
    id: 'djcity.billing.address.update.notification.error.message',
    defaultMessage: 'Error updating address, please try again later.',
  },
  updatedPassword: {
    id: 'djcity.account.password.update.notification.message',
    defaultMessage: 'You have successfully updated your password.',
  },
  welcome: {
    id: 'djcity.common.welcome.notification.message',
    defaultMessage: 'Welcome',
  },
  requestPasswordUpdate: {
    id: 'djcity.common.request.update_password.notification.message',
    defaultMessage:
      'If an account matches your email, you will receive an email with a temporary password',
  },
  subscribeLabelCrate: {
    id: 'djcity.common.crate.subscribe.label',
    defaultMessage: 'Subscribe',
  },
  freeDownloadPackErrorTitle: {
    id: 'djcity.common.campaign.free_download_pack.error',
    defaultMessage: 'Download Blocked',
  },
  freeDownloadPackUnlocked: {
    id: 'djcity.common.campaign.free_download_pack.unlocked',
    defaultMessage: 'Free Download Pack has been unlocked.',
  },
  freeDownloadPackUnlockedTitle: {
    id: 'djcity.common.campaign.free_download_pack.unlocked.title',
    defaultMessage: 'Download Unlocked!',
  },
  invalidCredentials: {
    id: 'djcity.account.error.username_password_incorrect',
    defaultMessage: 'Username or password incorrect. Please try again',
  },
  paymentDetailsSuccessTitle: {
    id: 'djcity.billing.paymentdetails.promocode.successTitle',
    defaultMessage: 'Success!',
  },
  paymentDetailsErrorTitle: {
    id: 'djcity.billing.paymentdetails.promocode.errorTitle',
    defaultMessage: 'Error!',
  },
})

export const defaultStringResources = [
  'djcity.account',
  'djcity.common',
  'djcity.billing',
  'djcity.records',
]

export const DEFAULT_KEY_INFORMATION = 'Any'

// Ensure searchTerm is populated
export function isEmptyOrSpaces(str) {
  return !str || str.match(/^ *$/) !== null
}

// Validate character minimum
export function isMinimumCharacters(str, charCount = 8) {
  return str && str.trim().length >= charCount
}

export const promoStringResource = (resource, values, offerName) => {
  const fallback = intl.formatMessage(resource, values)

  return offerName
    ? intl.formatMessage(
        {
          id: resource.id + '._' + offerName,
          defaultMessage: fallback,
        },
        values
      )
    : fallback
}

export const DEFAULT_SCRUB_TIME = 10

export const CURRENCY_SYMBOL_MAP = {
  USD: '$',
  JPY: '¥',
  GBP: '£',
  EUR: '€',
}

export function formatTrackLength(length) {
  const minutes = Math.floor(length / 60)
  const seconds = length % 60
  return `${minutes}:${zeroPadding(seconds)}`
}

export function zeroPadding(num) {
  return num.toString().padStart(2, '0')
}

export const MINIMUM_AUTOCOMPLETE_QUERY_LENGTH = 2

export const HIDE_LEARNMORE_BUTTON_ON_PAGES = /about|aboutus|free-download-pack|welcome-back/

export const SHOW_DEFAULT_LOGIN_HEADER_PROMOS = [
  PROMOS.NETNEW,
  PROMOS.REGULAR,
  PROMOS.SERATO,
]

export const SUBSCRIPTIONS_AVAILABLE = {
  MONTHLY: 'MONTHLY',
  BIANNUALLY: 'BIANNUALLY',
  ANNUALLY: 'ANNUALLY',
}

export const FORBIDDEN_CODES = [PROMOS.SERATO, PROMOS.NETNEW]
