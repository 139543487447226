import {
  MARK_TRACKS_AS_DOWNLOADED,
  RECEIVE_ADD_BATCH_TO_CRATE,
  RECEIVE_ADD_TO_CRATE,
  RECEIVE_ARTIST_SONGS,
  RECEIVE_CURRENT_USER_PREVIOUS_DOWNLOADS,
  RECEIVE_DOWNLOAD_TRACK,
  RECEIVE_EXCLUSIVES_SONGS,
  RECEIVE_GLOBAL_SONGS,
  RECEIVE_NEW_SONGS,
  RECEIVE_PLAYLISTS_SONGS,
  RECEIVE_REMOVE_ALL_FROM_CRATE,
  RECEIVE_REMOVE_BATCH_FROM_CRATE,
  RECEIVE_REMOVE_FROM_CRATE,
  RECEIVE_SEARCH_SONGS,
  RECEIVE_SEND_CRATE_TO_DOWNLOADER,
  RECEIVE_SONG_DETAILS,
  RECEIVE_SONGS_BY_CATEGORY,
  RECEIVE_SONGS_BY_REMIXER,
  RECEIVE_SONGS_BY_TAG,
  RECEIVE_SONGS_HOT_BOX,
  RECEIVE_SONGS_TOP_DOWNLOADS,
  RECEIVE_STATISTICS,
  RECEIVE_UPDATED_SONGS,
  REQUEST_EXCLUSIVES_SONGS,
  REQUEST_NEW_SONGS,
  SET_DOWNLOAD_TRACK_STATUS,
} from '../action_constants'
import cloneDeep from 'lodash/cloneDeep'
import keyBy from 'lodash/keyBy'

const defaultState = {
  isLoading: false,
  songsById: {},
}

function transformToSongStateList(tracks) {
  let records = {}
  tracks.forEach(
    record =>
      (records[parseInt(record.record.rid, 10)] = {
        rid: parseInt(record.record.rid, 10),
        artist: record.record.artist,
        title: record.record.title,
        featuring: record.record.featuring,
        remixer: record.record.remixer,
        lastModifiedDate: record.changeDate,
        meta: record.record.meta,
        types: record.record.types,
        previewUrl: record.record.previewUrl,
      })
  )

  return records
}

export default function songs(state = defaultState, action) {
  // TODO: use immer to simplify this reducer - https://immerjs.github.io/immer/docs/example-reducer
  const newState = cloneDeep(state)

  switch (action.type) {
    case REQUEST_NEW_SONGS:
    case REQUEST_EXCLUSIVES_SONGS:
      return { ...state, isLoading: true }
    case RECEIVE_EXCLUSIVES_SONGS:
    case RECEIVE_NEW_SONGS:
    case RECEIVE_GLOBAL_SONGS:
    case RECEIVE_SONGS_HOT_BOX:
    case RECEIVE_SONGS_TOP_DOWNLOADS:
    case RECEIVE_ARTIST_SONGS:
    case RECEIVE_SEARCH_SONGS:
    case RECEIVE_UPDATED_SONGS:
    case RECEIVE_SONGS_BY_CATEGORY:
    case RECEIVE_SONGS_BY_TAG:
    case RECEIVE_SONGS_BY_REMIXER:
      if (action.tracks) {
        return {
          ...state,
          isLoading: false,
          songsById: {
            ...state.songsById,
            ...transformToSongStateList(action.tracks),
          },
        }
      }
      if (!action.songs) {
        return state
      }
      return {
        ...state,
        isLoading: false,
        songsById: {
          ...state.songsById,
          ...keyBy(action.songs, 'rid'),
        },
      }
    case RECEIVE_CURRENT_USER_PREVIOUS_DOWNLOADS:
      if (!action.songs) {
        return state
      }
      return {
        ...state,
        songsById: {
          ...state.songsById,
          ...keyBy(
            action.songs.map(track => track.record),
            'rid'
          ),
        },
      }
    case RECEIVE_SONG_DETAILS:
      if (!action.song) {
        return state
      }
      return {
        ...state,
        songsById: {
          ...state.songsById,
          ...keyBy(action.song.related, 'rid'),
          ...keyBy(action.song.relatedNew, 'rid'),
          [action.song.rid]: action.song,
        },
      }
    case RECEIVE_STATISTICS:
      if (!action.data) {
        return state
      }
      return {
        ...state,
        songsById: {
          ...state.songsById,
          ...keyBy(action.data.recommendedRecordsByDownloadedGenres, 'rid'),
          ...keyBy(
            action.data.mostPopularRecordsByMostDownloadedMainGenre,
            'rid'
          ),
          ...keyBy(
            action.data.mostDownloadedRecordsByDownloadedRemixers,
            'rid'
          ),
        },
      }
    case SET_DOWNLOAD_TRACK_STATUS:
      if (!state.songsById[action.songId]) {
        return state
      }
      return {
        ...state,
        songsById: {
          ...state.songsById,
          [action.songId]: {
            ...state.songsById[action.songId],
            types: state.songsById[action.songId].types.map(item => {
              if (item.ttid !== action.trackId) {
                return item
              }
              return {
                ...item,
                isDownloading: action.isDownloading,
              }
            }),
          },
        },
      }
    case RECEIVE_DOWNLOAD_TRACK:
      if (!state.songsById[action.songId]) {
        return state
      }
      return {
        ...state,
        songsById: {
          ...state.songsById,
          [action.songId]: {
            ...state.songsById[action.songId],
            types: state.songsById[action.songId].types.map(item => {
              if (item.ttid !== action.trackId) {
                return item
              }
              return {
                ...item,
                downloadCount: item.downloadCount + 1,
                previouslyDownloaded: true,
                isDownloading: false,
              }
            }),
          },
        },
      }
    case RECEIVE_PLAYLISTS_SONGS:
      if (!action.playlists) {
        return state
      }
      return {
        ...state,
        songsById: {
          ...state.songsById,
          ...keyBy(
            action.playlists.reduce(
              (items, playlist) => items.concat(playlist.records.data),
              []
            ),
            'rid'
          ),
        },
      }
    case RECEIVE_ADD_TO_CRATE:
      if (newState.songsById[action.item.track.record.rid]) {
        newState.songsById[action.item.track.record.rid].types.forEach(type => {
          if (type.ttid === parseInt(action.item.track.ttid)) {
            type.inCrate = true
          }
        })
      }
      return newState
    case RECEIVE_ADD_BATCH_TO_CRATE:
      for (let item of action.items) {
        if (newState.songsById[item.track.record.rid]) {
          newState.songsById[item.track.record.rid].types.forEach(type => {
            if (type.ttid === parseInt(item.track.ttid)) {
              type.inCrate = true
            }
          })
        }
      }
      return newState
    case RECEIVE_REMOVE_FROM_CRATE:
      if (newState.songsById[action.songId]) {
        newState.songsById[action.songId].types.forEach(type => {
          if (type.ttid === parseInt(action.trackId)) {
            type.inCrate = false
          }
        })
      }
      return newState
    case RECEIVE_REMOVE_BATCH_FROM_CRATE:
      for (let trackId of action.trackIds) {
        if (newState.songsById[action.songId]) {
          newState.songsById[action.songId].types.forEach(type => {
            if (type.ttid === parseInt(trackId)) {
              type.inCrate = false
            }
          })
        }
      }
      return newState
    case RECEIVE_REMOVE_ALL_FROM_CRATE:
      Object.values(newState.songsById).forEach(song => {
        song.types.forEach(type => {
          type.inCrate = false
        })
      })
      return newState
    case RECEIVE_SEND_CRATE_TO_DOWNLOADER:
      action.items.forEach(item => {
        newState.songsById[item.rid] &&
          newState.songsById[item.rid].types.forEach(type => {
            if (type.ttid === item.ttid) {
              type.inCrate = false
            }
          })
      })
      return newState
    case MARK_TRACKS_AS_DOWNLOADED:
      action.records.forEach(item => {
        newState.songsById[item.songId].types.forEach(type => {
          if (type.ttid === parseInt(item.ttid)) {
            type.downloadCount = type.downloadCount + 1
            type.previouslyDownloaded = true
          }
        })
      })
      return newState
    default:
      return state
  }
}
