import * as currentUserApi from '../api/current_user'
import {
  activeSubscriptionSelector,
  isRefreshingSelector,
  loggedInSelector,
} from '../selectors/users'
import {
  AUTO_DISMISS_SECONDS,
  LONG_AUTO_DISMISS_SECONDS,
  NOTIFICATION_MESSAGES,
} from '../shared/utils'
import {
  CLEAR_CURRENT_USER,
  RECEIVE_CURRENT_USER,
  RECEIVE_CURRENT_USER_BILLING_HISTORY,
  RECEIVE_CURRENT_USER_PREVIOUS_DOWNLOADS,
  RECEIVE_LOGIN,
  RECEIVE_RESET_PASSWORD,
  RECEIVE_SIGNUP,
  RECEIVE_USER_PROFILE_ERROR,
  RECEIVE_USER_PROFILE_SUCCESS,
  REQUEST_CURRENT_USER,
  REQUEST_CURRENT_USER_BILLING_HISTORY,
  REQUEST_CURRENT_USER_INVOICE,
  REQUEST_CURRENT_USER_PREVIOUS_DOWNLOADS,
  REQUEST_LOGIN,
  REQUEST_RESET_PASSWORD,
  REQUEST_SIGNUP,
  REQUEST_USER_PROFILE,
  UPDATE_CURRENT_USER,
} from '../action_constants'
import { clearSongList } from './top_downloads_songs'
import { error, removeAll, success } from 'react-notification-system-redux'
import { FORGOT_PASSWORD_MODAL, openModal } from '../action_creators/modals'
import { getUserFeatures } from './features'
import { intl } from '../shared/IntlGlobalProvider'
import { push } from 'connected-react-router'
import { RECEIVE_CURRENT_USER_INVOICE, SIGN_UP_USER } from '../action_constants'
import { setDarkMode } from '../action_creators/dark_mode'
import { setLocale, updateLocale } from './locale'
import { updateSongsAsPlayed } from './player'
import { validateActiveSubscription } from './current_user_billing'
import get from 'lodash/get'

const gaEnabled =
  window &&
  !window.Cypress &&
  !process.env.IS_TEST &&
  process.env.GA_TRACKING_ID

let ReactGA
if (gaEnabled) {
  ReactGA = require('react-ga')
}

const successSavedUserNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.accountTitle),
    message: intl.formatMessage(NOTIFICATION_MESSAGES.accountActionSuccess),
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

const errorSavedUserNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.accountTitle),
    message: intl.formatMessage(NOTIFICATION_MESSAGES.accountActionError),
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

const forgotPasswordNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.forgotPasswordTitle),
    message: '',
    position: 'tr',
    autoDismiss: LONG_AUTO_DISMISS_SECONDS,
  }
}

const changePasswordNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.changePasswordTitle),
    message: '',
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

const loginDeniedNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.loginDeniedTitle),
    message: intl.formatMessage(NOTIFICATION_MESSAGES.loginDeniedBody),
    position: 'tr',
    autoDismiss: LONG_AUTO_DISMISS_SECONDS,
  }
}

const loginFailedNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.loginTitle),
    message: '',
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

const signupFailedNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.signupTitle),
    message: '',
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

const loginWelcome = (intl, message) => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.loginTitle),
    message: `${intl.formatMessage(NOTIFICATION_MESSAGES.welcome)} ${message}`,
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

const billingAddressNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.billingAddressTitle),
    message: '',
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

export function update(fields) {
  return {
    type: UPDATE_CURRENT_USER,
    fields,
  }
}

export function signUpSuccess(fields) {
  return {
    type: SIGN_UP_USER,
    fields,
  }
}

export function requestUserProfile(userId) {
  return {
    type: REQUEST_USER_PROFILE,
    userId,
  }
}

export function receiveUserProfile(profile) {
  return {
    type: RECEIVE_USER_PROFILE_SUCCESS,
    profile,
  }
}

export function receiveUserProfileError(error) {
  return {
    type: RECEIVE_USER_PROFILE_ERROR,
    error,
  }
}

export function requestLogin() {
  return {
    type: REQUEST_LOGIN,
  }
}

export function receiveLogin() {
  return {
    type: RECEIVE_LOGIN,
  }
}

export function requestSignup() {
  return {
    type: REQUEST_SIGNUP,
  }
}

export function receiveSignup() {
  return {
    type: RECEIVE_SIGNUP,
  }
}

const ERROR_PASSWORD_RESET_REQUIRED = 'PASSWORD_RESET_REQUIRED'
const LEGACY_PASSWORD_ERROR = 'LEGACY_PASSWORD_ERROR'
const INVALID_LOGIN_CREDENTIALS = 'INVALID_LOGIN_CREDENTIALS'

export function login(opts = {}) {
  return async function(dispatch, getState) {
    const { username, password } = opts
    const currentLocale = getState().locale.locale

    dispatch(requestLogin())
    return currentUserApi.login({ username, password }).then(res => {
      dispatch(receiveLogin())
      switch (res.status) {
        case 200:
          dispatch(update(res.data))
          dispatch(validateActiveSubscription())
          if (!res.data.user.activeSubscription) {
            dispatch(push('/payment-details'))
          }
          dispatch(clearSongList())
          if (currentLocale !== res.data.user.locale) {
            dispatch(setLocale(res.data.user.locale))
          }
          dispatch(setDarkMode(res.data.user.darkMode))
          dispatch(
            success({
              ...loginWelcome(intl, res.data.user.firstName),
            })
          )
          return
        case 400:
        case 401:
          if (res.data.error.type === ERROR_PASSWORD_RESET_REQUIRED) {
            dispatch(openModal(FORGOT_PASSWORD_MODAL))
            dispatch(
              error({
                ...forgotPasswordNotification(intl),
                message: res.data.error.userDescription,
              })
            )
          } else if (res.data.error.type === LEGACY_PASSWORD_ERROR) {
            dispatch(
              error({
                ...loginFailedNotification(intl),
                message: intl.formatMessage(NOTIFICATION_MESSAGES.loginError),
                action: {
                  label: intl.formatMessage(NOTIFICATION_MESSAGES.loginAction),
                  callback: () => dispatch(openModal(FORGOT_PASSWORD_MODAL)),
                },
              })
            )
          } else if (res.data.error.type === INVALID_LOGIN_CREDENTIALS) {
            dispatch(
              error({
                ...loginFailedNotification(intl),
                message: intl.formatMessage(
                  NOTIFICATION_MESSAGES.invalidCredentials
                ),
              })
            )
          } else {
            dispatch(
              error({
                ...loginFailedNotification(intl),
                message: res.data.error.userDescription,
              })
            )
          }
          return
        case 429:
          dispatch(error(loginDeniedNotification(intl)))
          return
        case 500:
          dispatch(
            error({
              ...loginFailedNotification(intl),
              message: intl.formatMessage(NOTIFICATION_MESSAGES.passwordError),
            })
          )
          return
        default:
          dispatch(
            error({
              ...forgotPasswordNotification(intl),
              message: res.data.error.userDescription,
            })
          )
      }
    })
  }
}

export function signup(opts = {}) {
  return async function(dispatch, getState) {
    const { locale, referral } = getState()
    const requestOpts = { ...opts, locale: locale.locale, referral }
    dispatch(requestSignup())
    try {
      const res = await currentUserApi.signup(requestOpts)
      switch (res.status) {
        case 200:
          if (gaEnabled) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'free_account_signup',
            })
            ReactGA.event({
              category: 'User Registration',
              action: 'Clicked Register Button',
            })
          }
          dispatch(signUpSuccess(res.data))
          dispatch(update(res.data))
          dispatch(push('/payment-details'))
          break
        case 400:
          dispatch(
            error({
              ...signupFailedNotification(intl),
              message: res.data.error.userDescription,
            })
          )
          break
        case 422:
          dispatch(
            error({
              ...signupFailedNotification(intl),
              message: res.data.error.userDescription,
            })
          )
          break
        case 401:
          dispatch(
            error({
              ...signupFailedNotification(intl),
              message: res.data.error.userDescription,
            })
          )
          break
        default:
          break
      }
    } finally {
      dispatch(receiveSignup())
    }
  }
}

function requestCurrentUser() {
  return {
    type: REQUEST_CURRENT_USER,
  }
}

function receiveCurrentUser(currentUser) {
  return {
    type: RECEIVE_CURRENT_USER,
    user: {
      ...currentUser,
      customerId: currentUser.customerId || currentUser.userId,
    },
  }
}

function requestPreviouslyDownloaded() {
  return { type: REQUEST_CURRENT_USER_PREVIOUS_DOWNLOADS }
}

function receivePreviouslyDownloaded({ songs, pagination }) {
  return { type: RECEIVE_CURRENT_USER_PREVIOUS_DOWNLOADS, songs, pagination }
}

export function fetchPreviouslyDownloaded(opts = {}) {
  return function(dispatch) {
    dispatch(requestPreviouslyDownloaded())
    return currentUserApi.fetchPreviouslyDownloaded(opts).then(res => {
      dispatch(
        receivePreviouslyDownloaded({
          songs: res.tracks,
          pagination: res.metadata,
        })
      )
    })
  }
}

export function saveCurrentUser(opts = {}, notify = true) {
  return function(dispatch) {
    dispatch(requestCurrentUser())
    return currentUserApi.putCurrentUser({ ...opts }).then(res => {
      if (res.ok) {
        notify && dispatch(success(successSavedUserNotification(intl)))
        dispatch(receiveCurrentUser(res.data))
      } else {
        notify &&
          dispatch(
            error({
              ...errorSavedUserNotification(intl),
              title: intl.formatMessage(NOTIFICATION_MESSAGES.accountTitle),
              message: res.data.error.userDescription,
            })
          )
      }
    })
  }
}

export function refreshUser() {
  return async function(dispatch, getState) {
    const state = getState()
    const isLoggedIn = loggedInSelector(state)
    const isRefreshing = isRefreshingSelector(state)
    const isActiveSubscription = activeSubscriptionSelector(state)
    if (isLoggedIn && isRefreshing && !isActiveSubscription) {
      return dispatch(fetchCurrentUser(false))
    }
  }
}

export function fetchCurrentUser(reload = true) {
  return async function(dispatch, getState) {
    const currentLocale = getState().locale.locale

    if (reload) {
      dispatch(requestCurrentUser())
    }
    const currentUser = await currentUserApi.fetchCurrentUser()
    if (!currentUser.error) {
      dispatch(receiveCurrentUser(currentUser))
      dispatch(getUserFeatures(currentUser.customerId))
      if (currentUser.locale !== currentLocale) {
        dispatch(updateLocale(currentUser.locale))
      }
    } else {
      dispatch(logout())
    }
  }
}

export function fetchCurrentUserProfile() {
  return function(dispatch, getState) {
    const userId = getState().currentUser.user.customerId
    dispatch(requestUserProfile(userId))
    return currentUserApi
      .fetchUserProfile()
      .then(profile => {
        dispatch(receiveUserProfile(profile))
      })
      .catch(error => receiveUserProfileError(error))
  }
}

export function requestResetPassword() {
  return { type: REQUEST_RESET_PASSWORD }
}

export function receiveResetPassword() {
  return { type: RECEIVE_RESET_PASSWORD }
}

export function forgotPassword(opts = {}) {
  return function(dispatch) {
    dispatch(requestResetPassword())
    return currentUserApi
      .forgotPassword(opts)
      .then(res => {
        if (res.ok) {
          return dispatch(
            success({
              ...forgotPasswordNotification(intl),
              message: intl.formatMessage(
                NOTIFICATION_MESSAGES.requestPasswordUpdate
              ),
            })
          )
        } else {
          return dispatch(
            error({
              ...forgotPasswordNotification(intl),
              message: res.data.error.userDescription,
            })
          )
        }
      })
      .finally(() => dispatch(receiveResetPassword()))
  }
}

export function updatePassword(opts = {}, inAccount = false) {
  return function(dispatch) {
    let notification = !inAccount
      ? forgotPasswordNotification
      : changePasswordNotification
    dispatch(requestResetPassword())
    return currentUserApi
      .updatePassword(opts, !inAccount)
      .then(res => {
        if (res.ok) {
          dispatch(removeAll())
          dispatch(update(res.data))
          if (!inAccount) {
            dispatch(push('/'))
          }
          dispatch(
            success({
              ...notification(intl),
              message: intl.formatMessage(
                NOTIFICATION_MESSAGES.updatedPassword
              ),
            })
          )
        } else {
          dispatch(
            error({
              ...notification(intl),
              message: res.data.error.userDescription,
            })
          )
          if (res.status === 410 && opts.code) {
            dispatch(openModal(FORGOT_PASSWORD_MODAL))
          }
        }
        return res.ok
      })
      .finally(() => dispatch(receiveResetPassword()))
  }
}

function requestCurrentUserBillingHistory() {
  return { type: REQUEST_CURRENT_USER_BILLING_HISTORY }
}

function receiveCurrentUserBillingHistory({ invoices }) {
  return { type: RECEIVE_CURRENT_USER_BILLING_HISTORY, invoices }
}

export function fetchCurrentUserBillingHistory() {
  return function(dispatch) {
    dispatch(requestCurrentUserBillingHistory())
    return currentUserApi.fetchCurrentUserBillingHistory().then(res => {
      dispatch(
        receiveCurrentUserBillingHistory({
          invoices: res,
        })
      )
    })
  }
}

function requestCurrentUserInvoice() {
  return { type: REQUEST_CURRENT_USER_INVOICE }
}

function receiveCurrentUserInvoice({ invoice }) {
  return { type: RECEIVE_CURRENT_USER_INVOICE, invoice }
}

function logoutUser() {
  return {
    type: CLEAR_CURRENT_USER,
  }
}

export function logout() {
  return function(dispatch) {
    // Mark songs as played if necessary upon logout
    dispatch(updateSongsAsPlayed())
    dispatch(logoutUser())
    dispatch(push('/about'))
  }
}

export function fetchCurrentUserInvoice(id) {
  return function(dispatch) {
    dispatch(requestCurrentUserInvoice())
    return currentUserApi.fetchCurrentUserInvoice(id).then(res => {
      dispatch(
        receiveCurrentUserInvoice({
          invoice: res,
        })
      )
    })
  }
}

export function updateBillingAddress(billingAddress = {}) {
  return async function(dispatch) {
    try {
      const res = await currentUserApi.updateUser({ billingAddress })
      if (!res.userId) {
        throw new Error(
          get(
            res,
            'data.error.userDescription',
            intl.formatMessage(NOTIFICATION_MESSAGES.notUpdatedBillingAddress)
          )
        )
      }
      dispatch(
        success({
          ...billingAddressNotification(intl),
          message: intl.formatMessage(
            NOTIFICATION_MESSAGES.updatedBillingAddress
          ),
        })
      )
    } catch (err) {
      dispatch(
        error({
          ...billingAddressNotification(intl),
          message: err.message,
        })
      )
    }
  }
}
