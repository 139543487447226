import * as playerActions from 'spa/action_creators/player'
import * as searchActions from 'spa/action_creators/search_songs'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import { filterInt } from 'spa/shared/utils'
import { FormattedHTMLMessage } from 'react-intl'
import { isMobileSelector } from '../../selectors/device'
import { queuedSongsSelector } from 'spa/selectors/songs'
import { SongPropType } from 'spa/api/songs'
import { SongTableRow } from './song_table_row'
import { Table } from 'spa/components/table'
import { UserPropType } from 'spa/api/current_user'
import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const SongTable = ({
  addSongToQueue,
  addSongsToQueue,
  removeSongFromQueue,
  className,
  currentUser,
  firstColumnHeaderLabel,
  sortByDate,
  sort,
  showNumbers,
  songs,
  nextSongs = [],
  queuedSongIds,
  isSearch,
  viewSearchSong,
  trackList,
  canHide,
}) => {
  const isMobile = useSelector(isMobileSelector)
  const columnHeaders = [firstColumnHeaderLabel]
  const onColumnClicks = [null]
  const columnSort = [null]
  if (isMobile) columnHeaders.push('')
  else {
    if (sortByDate) {
      columnHeaders.push(
        <FormattedHTMLMessage
          defaultMessage="DATE ADDED"
          id="djcity.records.recordpool.track.date_added"
          key="date_added"
        />
      )
      if (!showNumbers) {
        onColumnClicks.push(sortByDate)
        columnSort.push(sort)
      }
    }

    columnHeaders.push(
      <FormattedHTMLMessage
        defaultMessage="BPM"
        id="djcity.records.recordpool.track.bpm"
        key="bpm"
      />
    )

    columnHeaders.push(
      <FormattedHTMLMessage
        defaultMessage="Genre"
        id="djcity.records.recordpool.track.genre"
        key="genre"
      />
    )

    columnHeaders.push('')
  }
  if (canHide) {
    columnHeaders.push('')
  }

  const { autoplay } = useSelector(state => state.userPreferences)
  const { currentSongId } = useSelector(state => state.player)

  const handleClickBubble = e => {
    const { listIndex } = e.target.dataset
    const songIndex = filterInt(listIndex)
    if (
      autoplay &&
      typeof songIndex === 'number' &&
      songs[songIndex] &&
      parseInt(currentSongId) !== parseInt(songs[songIndex].rid)
    ) {
      let songsToEnqueue = songs
      // only if last song is played, enque next group of songs
      if (parseInt(songs.at(-1)) === parseInt(songs[songIndex]))
        songsToEnqueue = songsToEnqueue.concat(nextSongs)
      if (songsToEnqueue.length) addSongsToQueue(songsToEnqueue)
    }
  }

  return (
    <Table
      className={cn(
        {
          [styles.songTable]: !canHide,
          [styles.dualSongTable]: canHide,
          [styles.songTableWithDateColumn]: sortByDate,
        },
        className
      )}
      columns={columnHeaders}
      onHeaderClick={onColumnClicks}
      sort={columnSort}
    >
      <div className={cn(styles.songTableRow)} onClick={handleClickBubble}>
        {songs.map((song, index) => (
          <SongTableRow
            canHide={canHide}
            currentlyInQueue={queuedSongIds.includes(song.rid)}
            index={showNumbers ? index + 1 : undefined}
            key={song.rid}
            listIndex={index}
            onAddSongToQueue={currentUser ? addSongToQueue : undefined}
            onRemoveSongFromQueue={
              currentUser ? removeSongFromQueue : undefined
            }
            onSelect={isSearch ? viewSearchSong : undefined}
            showDateAdded={sortByDate}
            song={song}
            songIdx
            trackList={trackList}
          />
        ))}
      </div>
    </Table>
  )
}

SongTable.propTypes = {
  addSongToQueue: PropTypes.func.isRequired,
  addSongsToQueue: PropTypes.func.isRequired,
  canHide: PropTypes.bool,
  className: PropTypes.string,
  currentUser: UserPropType,
  firstColumnHeaderLabel: PropTypes.node,
  isSearch: PropTypes.bool,
  nextSongs: PropTypes.arrayOf(SongPropType),
  queuedSongIds: PropTypes.arrayOf(PropTypes.string),
  removeSongFromQueue: PropTypes.func.isRequired,
  showNumbers: PropTypes.bool,
  songs: PropTypes.arrayOf(SongPropType),
  sort: PropTypes.number,
  sortByDate: PropTypes.bool,
  trackList: PropTypes.string,
  viewSearchSong: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    queuedSongIds: queuedSongsSelector(state).map(song => song.rid),
    currentUser: state.currentUser.user,
  }
}

function mapDispatchToProps(dispatch) {
  const {
    addSongToQueue,
    addSongsToQueue,
    clearQueue,
    removeSongFromQueue,
  } = bindActionCreators(playerActions, dispatch)
  const { viewSearchSong } = bindActionCreators(searchActions, dispatch)

  return {
    addSongToQueue,
    addSongsToQueue,
    clearQueue,
    removeSongFromQueue,
    viewSearchSong,
  }
}

const ConnectedSongTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(SongTable)

export { ConnectedSongTable as SongTable }
