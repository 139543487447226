import { defineMessages, injectIntl } from 'react-intl'
import { FilterButtonIcon } from './filter_button_icon'
import { Popover } from 'spa/components/popover'
import classNames from 'classnames'
import MonthFilterPanel from './month_filter_panel'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

// TODO: i18n?

export const localizedPopoverMessages = defineMessages({
  Jan: {
    id: 'djcity.common.filter.jan',
    defaultMessage: 'Jan',
  },
  Feb: {
    id: 'djcity.common.filter.feb',
    defaultMessage: 'Feb',
  },
  Mar: {
    id: 'djcity.common.filter.march',
    defaultMessage: 'Mar',
  },
  Apr: {
    id: 'djcity.common.filter.april',
    defaultMessage: 'Apr',
  },
  May: {
    id: 'djcity.common.filter.may',
    defaultMessage: 'May',
  },
  Jun: {
    id: 'djcity.common.filter.june',
    defaultMessage: 'Jun',
  },
  Jul: {
    id: 'djcity.common.filter.jul',
    defaultMessage: 'Jul',
  },
  Aug: {
    id: 'djcity.common.filter.aug',
    defaultMessage: 'Aug',
  },
  Sep: {
    id: 'djcity.common.filter.sep',
    defaultMessage: 'Sep',
  },
  Oct: {
    id: 'djcity.common.filter.oct',
    defaultMessage: 'Oct',
  },
  Nov: {
    id: 'djcity.common.filter.nov',
    defaultMessage: 'Nov',
  },
  Dec: {
    id: 'djcity.common.filter.dec',
    defaultMessage: 'Dec',
  },
})

export const POPOVER_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export function encodeDate(date) {
  if (date instanceof Date) {
    return `${date.getMonth() + 1}:${date.getFullYear()}`
  }
  if (date.hasOwnProperty('month') && date.hasOwnProperty('year')) {
    return `${date.month}:${date.year}`
  }
  throw new Error('Unexpected format: ', date)
}

class MonthPopover extends React.PureComponent {
  static propTypes = {
    intl: PropTypes.shape({
      formatDate: PropTypes.func,
      formatMessage: PropTypes.func,
    }).isRequired,
    isMobile: PropTypes.bool,
    monthInput: PropTypes.objectOf(PropTypes.string),
    monthsToShow: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    skipMonths: PropTypes.number,
    value: PropTypes.string,
  }

  static defaultProps = {
    monthsToShow: 12,
    skipMonths: 0,
  }

  render() {
    const {
      monthsToShow,
      onChange,
      value,
      skipMonths,
      monthInput,
      intl,
      isMobile,
    } = this.props

    let monthValues = {}
    if (monthInput) {
      monthValues = monthInput
    } else {
      for (let i = skipMonths; i < skipMonths + monthsToShow; i++) {
        const date = new Date()
        date.setDate(1)
        date.setMonth(date.getMonth() - i)
        monthValues[encodeDate(date)] = `${intl.formatMessage(
          localizedPopoverMessages[POPOVER_MONTHS[date.getMonth()]]
        )}, ${date.getFullYear()}`
      }
    }
    return (
      <Popover
        closeOnScroll={false}
        contentClassName={styles.filterPanel}
        renderTrigger={({ handleClick, open, ref }) => (
          <button
            className={classNames({
              popoverTrigger: !open,
              popoverTriggerOpen: open,
              mobile: isMobile,
            })}
            onClick={handleClick}
            ref={ref}
          >
            {isMobile ? (
              <>
                <FilterButtonIcon isMobile={isMobile} isOpen={open} />{' '}
                {monthValues[value]}
              </>
            ) : (
              <>
                {monthValues[value]} <FilterButtonIcon isOpen={open} />
              </>
            )}
          </button>
        )}
      >
        <MonthFilterPanel
          isMobile={isMobile}
          monthValues={monthValues}
          onChange={onChange}
          value={value}
        />
      </Popover>
    )
  }
}

const ConnectedMonthPopover = injectIntl(MonthPopover)
export { ConnectedMonthPopover as MonthPopover }
