import { BlankLayout } from '../blank_layout'
import { DEFAULT_SHORT_DATE_OPTION } from '../../shared/utils'
import { fetchCurrentUserInvoice } from 'spa/action_creators/current_user'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Logo } from 'spa/components/logo'
import { TextStyle } from '../typography'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import styles from './styles'

export function getLocalizablePaymentMethod(intl, paymentMethod) {
  const paymentTypeId = `djcity.billing.invoice.payment_method.${paymentMethod.toLowerCase()}`
  return intl.formatMessage({
    id: paymentTypeId,
    defaultMessage: paymentMethod,
  })
}

const UserInvoice = ({ intl }) => {
  const dispatch = useDispatch()
  const invoice = useSelector(state => state.currentUser.invoice)
  const { id } = useParams()

  useEffect(() => {
    dispatch(fetchCurrentUserInvoice(id))
  }, [dispatch, id])

  if (!invoice || !invoice.paymentMethod) {
    return null
  }

  const invoiceDateId = `#${invoice.paymentId} - ${intl.formatDate(
    invoice.date,
    DEFAULT_SHORT_DATE_OPTION
  )}`

  return (
    <BlankLayout>
      <Helmet defer={false}>
        <title key="pagetitle">
          {intl.formatMessage({
            id: 'djcity.billing.invoice.title',
            defaultMessage: 'Invoice',
          })}{' '}
          {invoiceDateId} | DJcity
        </title>
      </Helmet>
      <div className={styles.invoice}>
        <div className={styles.printOverlay}>
          <a href="javascript:if(window.print)window.print()">
            <FontAwesomeIcon icon="print" />
          </a>
        </div>
        {/*HEAD*/}
        <div className={styles.invoiceRow}>
          <div className={styles.invoiceBlock}>
            <Logo darkMode />
          </div>
          <div className={styles.invoiceBlock}>
            <TextStyle className={styles.headTitle} uppercase variant="bold">
              <FormattedMessage
                defaultMessage="Invoice"
                id="djcity.billing.invoice.title"
              />
            </TextStyle>
            <br />
            <TextStyle color="grey" variant="label">
              {invoiceDateId}
            </TextStyle>
          </div>
        </div>
        {/* / HEAD*/}

        {/* Address*/}
        <div className={styles.invoiceRow}>
          <div className={styles.invoiceBlock}>
            <div>
              <TextStyle variant="bold">
                {invoice.companyAddress.addressLine1}
              </TextStyle>
            </div>
            <div>
              <TextStyle variant="label">
                {`${invoice.companyAddress.city}, ${invoice.companyAddress.state} ${invoice.companyAddress.zip}`}
              </TextStyle>
            </div>
            <div>
              <TextStyle variant="label">
                {invoice.companyAddress.country}
              </TextStyle>
            </div>
          </div>
        </div>
        {/* / Address */}

        <div className={styles.invoiceRow}>
          {/*Billing address*/}
          <div className={styles.invoiceBlock}>
            <div className={styles.bottomMargin}>
              <TextStyle color="grey" uppercase variant="default">
                <FormattedMessage
                  defaultMessage="Bill to"
                  id="djcity.billing.invoice.bill_to"
                />
              </TextStyle>
            </div>
            <div>
              <TextStyle variant="bold">
                {`${invoice.billingAddress.firstName} ${invoice.billingAddress.lastName}`}
              </TextStyle>
            </div>
            <div>
              <TextStyle>{invoice.billingAddress.addressLine1}</TextStyle>
            </div>
            {invoice.billingAddress.addressLine2 ? (
              <div>
                <TextStyle>
                  {invoice.billingAddress.addressLine2}
                  {invoice.billingAddress.suite
                    ? ` - ${invoice.billingAddress.suite}`
                    : null}
                </TextStyle>
              </div>
            ) : null}
            <div>
              <TextStyle variant="label">
                {invoice.billingAddress.city && invoice.billingAddress.state
                  ? `${invoice.billingAddress.city}, ${invoice.billingAddress.state} ${invoice.billingAddress.zip}`
                  : ''}
              </TextStyle>
            </div>
            <div>
              <TextStyle variant="label">
                {invoice.billingAddress.country}
              </TextStyle>
            </div>
            {invoice.additionalInfo && (
              <div>
                <TextStyle variant="label">{invoice.additionalInfo}</TextStyle>
              </div>
            )}
          </div>
          {/* / Billing address*/}

          {/* Date And Balance*/}
          <div className={styles.invoiceBlock}>
            <div className={styles.bottomMargin}>
              <TextStyle color="grey" variant="label">
                <FormattedMessage
                  defaultMessage="Date"
                  id="djcity.billing.invoice.date"
                />
                :&nbsp;
              </TextStyle>
              <TextStyle>
                {intl.formatDate(invoice.date, DEFAULT_SHORT_DATE_OPTION)}
              </TextStyle>
            </div>
            <div className={styles.greyBox}>
              <TextStyle variant="bold">
                <FormattedMessage
                  defaultMessage="Balance due"
                  id="djcity.billing.invoice.balance_due"
                />
                : {`${invoice.currencySymbol} ${invoice.balanceDue}`}
              </TextStyle>
            </div>
          </div>
          {/*  / Date And Balance */}
        </div>

        {/*  Payment Method Table */}
        <div className={styles.invoiceRow}>
          <div className={styles.invoiceTableBlock}>
            <table>
              <thead>
                <tr>
                  <th className={styles.alignLeft}>
                    <TextStyle color="white" variant="bold">
                      <FormattedMessage
                        defaultMessage="Payment method"
                        id="djcity.billing.invoice.payment_method"
                      />
                    </TextStyle>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {getLocalizablePaymentMethod(intl, invoice.paymentMethod)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* / Payment Method Table */}

        {/*  Items table */}
        <div className={styles.invoiceRow}>
          <div className={styles.invoiceTableBlock}>
            <table>
              <thead>
                <tr>
                  <th className={styles.alignLeft}>
                    <TextStyle color="white" variant="bold">
                      <FormattedMessage
                        defaultMessage="Item"
                        id="djcity.billing.invoice.item"
                      />
                    </TextStyle>
                  </th>
                  <th className={styles.alignRight}>
                    <TextStyle color="white" variant="bold">
                      <FormattedMessage
                        defaultMessage="Quantity"
                        id="djcity.billing.invoice.quantity"
                      />
                    </TextStyle>
                  </th>
                  {/* <th className={styles.alignRight}>
                    <TextStyle color="white" variant="bold">
                      <FormattedMessage
                        defaultMessage="Rate"
                        id="djcity.billing.invoice.rate"
                      />
                    </TextStyle>
                  </th> */}
                  <th className={styles.alignRight}>
                    <TextStyle color="white" variant="bold">
                      <FormattedMessage
                        defaultMessage="Amount"
                        id="djcity.billing.invoice.amount"
                      />
                    </TextStyle>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.alignLeft}>{invoice.product}</td>
                  <td className={styles.alignRight}>{invoice.quantity}</td>
                  {/* <td
                    className={styles.alignRight}
                  >{`${invoice.currencySymbol} ${invoice.subtotal}`}</td> */}
                  <td
                    className={styles.alignRight}
                  >{`${invoice.currencySymbol} ${invoice.total}`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* / Payment Method Table */}
        <div className={styles.invoiceRow}>
          <div
            className={classNames(styles.alignRight, styles.invoiceFullBlock)}
          >
            <TextStyle variant="bold">
              <FormattedMessage
                defaultMessage="Total"
                id="djcity.billing.invoice.total"
              />{' '}
              {`${invoice.currencyId} ${invoice.currencySymbol} ${invoice.total}`}
            </TextStyle>
          </div>
        </div>
      </div>
    </BlankLayout>
  )
}

UserInvoice.displayName = 'UserInvoice'

UserInvoice.propTypes = {
  fetchCurrentUserInvoice: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatDate: PropTypes.func.isRequired,
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export default injectIntl(UserInvoice)
