import * as localeActions from 'spa/action_creators/locale'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Knotch } from 'spa/components/knotch'
import { Link } from 'spa/components/link'
import { LOCALES } from '../../../locale_data/config'
import { TextStyle } from 'spa/components/typography'
import PropTypes from 'prop-types'
import React from 'react'

import { Logo } from '../logo'
import styles from './styles'

const localizeMessages = defineMessages({
  instagram: {
    id: 'djcity.common.social.instagram',
    defaultMessage: 'https://www.instagram.com/djcity/',
  },
  facebook: {
    id: 'djcity.common.social.facebook',
    defaultMessage: 'https://facebook.com/djcity',
  },
  twitter: {
    id: 'djcity.common.social.twitter',
    defaultMessage: 'https://twitter.com/djcity/',
  },
  logoWhite: {
    id: 'djcity.common.images.logoWhite',
    defaultMessage: '/assets/cdn/logo_djcity_w.png',
  },
  newsletterButton: {
    id: 'djcity.common.newsletter.button',
    defaultMessage: 'Newsletter signup',
  },
  papLink: {
    id: 'djcity.common.affiliate.papUrl',
    defaultMessage:
      'https://loopmasters.postaffiliatepro.com/affiliates/signup.php#SignupForm',
  },
  faqUrl: {
    id: 'djcity.common.faq.url',
    defaultMessage: 'https://support.djcity.com/hc',
  },
})

export class Footer extends React.Component {
  setLocale(locale) {
    this.props.localeActions.setLocale(locale)
    window.scrollTo(0, 0)
  }

  render() {
    const { locale, intl } = this.props

    return (
      <footer className={styles.footer} id="footer">
        <div className={styles.wrapper}>
          <div className={styles.logoColumn}>
            <div className={styles.logoContainer}>
              <Link className={styles.logoLink} to="/">
                <Logo alt />
              </Link>
            </div>
            <div className={styles.newsletterContainer}>
              <TextStyle color="white" variant="label">
                <FormattedMessage
                  defaultMessage="Get the latesst DJ news, music charts, and updates in your mailbox"
                  id="djcity.common.master.footer.subscribe"
                />
              </TextStyle>
              <div>
                <button
                  className={styles.subscribeButton}
                  onClick={() =>
                    window.open(
                      'https://visitor.r20.constantcontact.com/d.jsp?llr=4uvyxxn6&p=oi&m=4uvyxxn6&sit=sfyr4ne4&f=735da7af-d8d3-4636-885d-f24d54087394',
                      '_blank',
                      'noopener'
                    )
                  }
                >
                  {intl.formatMessage(localizeMessages.newsletterButton)}
                </button>
              </div>
            </div>
            <div
              className="fb-like"
              data-action="like"
              data-href="//www.facebook.com/djcity"
              data-layout="standard"
              data-show-faces="true"
            />
          </div>
          <div className={styles.columns}>
            <div className={styles.column}>
              <Knotch className={styles.knotch} />
              <h4 className={styles.columnHeader}>
                <TextStyle color="white" uppercase variant="extra-bold">
                  <FormattedMessage
                    defaultMessage="Site"
                    id="djcity.common.master.footer.site"
                  />
                </TextStyle>
              </h4>
              <ul>
                <li>
                  <Link to="/">
                    <FormattedMessage
                      defaultMessage="Home"
                      id="djcity.common.master.footer.home.v2"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/terms-of-use">
                    <FormattedMessage
                      defaultMessage="Terms of Use"
                      id="djcity.common.terms_of_use"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    <FormattedMessage
                      defaultMessage="Privacy Policy"
                      id="djcity.common.master.footer.privacy.v2"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/cookies">
                    <FormattedMessage
                      defaultMessage="Cookie Policy"
                      id="djcity.common.master.footer.cookies"
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.column}>
              <Knotch className={styles.knotch} />
              <h4 className={styles.columnHeader}>
                <TextStyle color="white" uppercase variant="extra-bold">
                  <FormattedMessage
                    defaultMessage="Company"
                    id="djcity.common.master.footer.company"
                  />
                </TextStyle>
              </h4>
              <ul>
                <li>
                  <Link to="/about-us">
                    <FormattedMessage
                      defaultMessage="About Us"
                      id="djcity.common.master.footer.about.v2"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us">
                    <FormattedMessage
                      defaultMessage="Contact Us"
                      id="djcity.common.master.footer.contact.v2"
                    />
                  </Link>
                </li>
                <li>
                  <a
                    href={intl.formatMessage(localizeMessages.faqUrl)}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage
                      defaultMessage="FAQ"
                      id="djcity.common.footer.faq"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={intl.formatMessage(localizeMessages.papLink)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage
                      defaultMessage="Affiliate Program"
                      id="djcity.common.affiliate"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.column}>
              <Knotch className={styles.knotch} />
              <h4 className={styles.columnHeader}>
                <TextStyle color="white" uppercase variant="extra-bold">
                  <FormattedMessage
                    defaultMessage="Languages"
                    id="djcity.common.master.footer.languages"
                  />
                </TextStyle>
              </h4>
              <ul>
                <li>
                  <Link
                    onClick={this.setLocale.bind(this, LOCALES.en_US)}
                    to="/"
                  >
                    <TextStyle
                      color="white"
                      variant={
                        locale === LOCALES.en_US ? 'extra-bold' : 'default'
                      }
                    >
                      English (US)
                    </TextStyle>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={this.setLocale.bind(this, LOCALES.en_GB)}
                    to="/"
                  >
                    <TextStyle
                      color="white"
                      variant={
                        locale === LOCALES.en_GB ? 'extra-bold' : 'default'
                      }
                    >
                      English (UK)
                    </TextStyle>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={this.setLocale.bind(this, LOCALES.es_ES)}
                    to="/"
                  >
                    <TextStyle
                      color="white"
                      variant={
                        locale === LOCALES.es_ES ? 'extra-bold' : 'default'
                      }
                    >
                      Español
                    </TextStyle>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={this.setLocale.bind(this, LOCALES.ja_JP)}
                    to="/"
                  >
                    <TextStyle
                      color="white"
                      variant={
                        locale === LOCALES.ja_JP ? 'extra-bold' : 'default'
                      }
                    >
                      日本語
                    </TextStyle>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={this.setLocale.bind(this, LOCALES.de_DE)}
                    to="/"
                  >
                    <TextStyle
                      color="white"
                      variant={
                        locale === LOCALES.de_DE ? 'extra-bold' : 'default'
                      }
                    >
                      Deutsch
                    </TextStyle>
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.column}>
              <Knotch className={styles.knotch} />
              <h4 className={styles.columnHeader}>
                <TextStyle color="white" uppercase variant="extra-bold">
                  <FormattedMessage
                    defaultMessage="Connect"
                    id="djcity.common.footer.connect"
                  />
                </TextStyle>
              </h4>
              <ul>
                <li className={styles.columnHeader}>
                  <a
                    className={styles.socialMediaLink}
                    href={intl.formatMessage(localizeMessages.facebook)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-square']} />
                  </a>
                  <a
                    className={styles.socialMediaLink}
                    href={intl.formatMessage(localizeMessages.instagram)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                  </a>
                  <a
                    className={styles.socialMediaLink}
                    href={intl.formatMessage(localizeMessages.twitter)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={['fab', 'twitter-square']} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
Footer.propTypes = {
  children: PropTypes.node,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  localeActions: PropTypes.shape({
    setLocale: PropTypes.func.isRequired,
  }),
}
function mapStateToProps(state) {
  return {
    locale: state.locale.locale,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    localeActions: bindActionCreators(localeActions, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Footer))
