/* eslint-disable no-console */
import * as currentUserBillingApi from '../api/current_user_billing'
import {
  CANCELED_SUBSCRIPTION,
  CANCELING_SUBSCRIPTION,
  ERROR_CANCELING_SUBSCRIPTION,
  ERROR_RECEIVING_PAYMENT_METHOD,
  REACTIVATING_SUBSCRIPTION,
  RECEIVE_PAYMENT_METHOD,
  RECEIVE_PAYMENT_METHOD_CHANGE_ERROR,
  RECEIVE_PAYMENT_METHOD_CHANGE_SUCCESS,
  RECEIVE_PAYMENT_SUCCESS,
  REQUEST_PAYMENT_METHOD,
  VALIDATED_SUBSCRIPTION_STATUS,
  VALIDATING_SUBSCRIPTION_STATUS,
} from '../action_constants'
import { error, success } from 'react-notification-system-redux'
import { intl } from '../shared/IntlGlobalProvider'

import {
  AUTO_DISMISS_SECONDS,
  // calculateDaysPast,
  LONG_AUTO_DISMISS_SECONDS,
  NOTIFICATION_MESSAGES,
} from '../shared/utils'
import {
  ERROR_REACTIVATING_SUBSCRIPTION,
  REACTIVATED_SUBSCRIPTION,
  RECEIVE_PAYMENT_ERROR,
  REQUEST_PAYMENT,
  REQUEST_PAYMENT_METHOD_CHANGE,
} from '../action_constants'
import { push } from 'connected-react-router'
import { removePromoCode } from './promos'
import { removeSubscription } from './subscriptions'

const gaEnabled =
  window &&
  !window.Cypress &&
  !process.env.IS_TEST &&
  process.env.GA_TRACKING_ID

let ReactGA
if (gaEnabled) {
  ReactGA = require('react-ga')
}

const successPaymentNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.checkoutTitle),
    message: intl.formatMessage(NOTIFICATION_MESSAGES.paymentProcessed),
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

const successCardChangeNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.billingTitle),
    message: intl.formatMessage(NOTIFICATION_MESSAGES.paymentUpdated),
    position: 'tr',
    autoDismiss: 5,
  }
}

const successCancellationNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.billingTitle),
    message: intl.formatMessage(NOTIFICATION_MESSAGES.subscriptionCanceled),
    position: 'tr',
    autoDismiss: 5,
  }
}

const successReactivationNotification = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.billingTitle),
    message: intl.formatMessage(NOTIFICATION_MESSAGES.subscriptionReactivated),
    position: 'tr',
    autoDismiss: 5,
  }
}

const checkoutError = message => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.error),
    message:
      message instanceof Error
        ? message.userDescription
        : typeof message === 'object'
        ? intl.formatMessage(message)
        : message,
    position: 'tr',
    autoDismiss: LONG_AUTO_DISMISS_SECONDS,
  }
}

const errorBilling = intl => {
  return {
    title: intl.formatMessage(NOTIFICATION_MESSAGES.billingTitle),
    message: intl.formatMessage(NOTIFICATION_MESSAGES.error),
    position: 'tr',
    autoDismiss: AUTO_DISMISS_SECONDS,
  }
}

function requestPayment() {
  return {
    type: REQUEST_PAYMENT,
  }
}

function trackPurchaseEvent(analytics) {
  if (gaEnabled) {
    window.dataLayer.push({
      enhanced_conversion_data: {
        email: analytics.email,
      },
    })
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        affiliation: 'DJ City',
        transaction_id: analytics.paymentId,
        value: analytics.price,
        tax: 0,
        shipping: 0,
        currency: analytics.currency,
        coupon: analytics.promoName,
        items: [
          {
            item_id: analytics.subscriptionId,
            item_name: analytics.subscriptionName,
            item_brand: 'DJ City',
            item_category: 'Subscriptions',
            item_variant: `${analytics.subscriptionDuration} month`,
            coupon: analytics.promoCode,
            price: analytics.price,
            quantity: 1,
          },
        ],
      },
    })

    ReactGA.event({
      category: 'User Registration',
      action: 'Payment Successful',
    })
  }

  if (window.fbq)
    window.fbq(
      'track',
      'Purchase',
      {
        value: analytics.price,
        currency: analytics.currency,
      },
      { eventID: analytics.paymentId }
    )
}

function receivePaymentSuccess(processor) {
  return {
    type: RECEIVE_PAYMENT_SUCCESS,
    processor,
  }
}

function receivePaymentError(processor, error = null) {
  return {
    type: RECEIVE_PAYMENT_ERROR,
    processor,
    error,
  }
}

// Cancel Subscription
function requestCancelSubscription(userId) {
  return {
    type: CANCELING_SUBSCRIPTION,
    userId,
  }
}

function receiveCancelSubscription(userId) {
  return {
    type: CANCELED_SUBSCRIPTION,
    userId,
  }
}

function receiveCancelSubscriptionError(error) {
  return {
    type: ERROR_CANCELING_SUBSCRIPTION,
    error,
  }
}

// Reactivate Subscription
function requestReactivateSubscription(userId) {
  return {
    type: REACTIVATING_SUBSCRIPTION,
    userId,
  }
}

function receiveReactivateSubscription(userId) {
  return {
    type: REACTIVATED_SUBSCRIPTION,
    userId,
  }
}

function receiveReactivateSubscriptionError(error) {
  return {
    type: ERROR_REACTIVATING_SUBSCRIPTION,
    error,
  }
}

function validatingSubscriptionStatus() {
  return {
    type: VALIDATING_SUBSCRIPTION_STATUS,
    error,
  }
}

function validatedSubscriptionStatus() {
  return {
    type: VALIDATED_SUBSCRIPTION_STATUS,
    error,
  }
}

export function cancelSubscription() {
  return function(dispatch, getState) {
    const userId = getState().currentUser.user.customerId
    dispatch(requestCancelSubscription(userId))
    currentUserBillingApi
      .cancelSubscription(userId)
      .then(res => {
        if (res.ok) {
          dispatch(receiveCancelSubscription(res))
          dispatch(success(successCancellationNotification(intl)))
        } else {
          dispatch(
            error({
              ...errorBilling(intl),
              message: res.data.error.userDescription,
            })
          )
        }
      })
      .catch(error => {
        dispatch(error(errorBilling(intl)))
        dispatch(receiveCancelSubscriptionError(error))
      })
  }
}

export function reactivateSubscription() {
  return async function(dispatch, getState) {
    const userId = getState().currentUser.user.customerId
    dispatch(requestReactivateSubscription(userId))
    try {
      const response = await currentUserBillingApi.reactivateSubscription(
        userId
      )
      if (response.ok) {
        dispatch(receiveReactivateSubscription(response))
        dispatch(success(successReactivationNotification(intl)))
        dispatch(push('/'))
      } else {
        dispatch(
          error({
            ...errorBilling(intl),
            message: response.data.error.userDescription,
          })
        )
        dispatch(receiveReactivateSubscriptionError(response.data.error))
      }
    } catch (e) {
      dispatch(error(errorBilling(intl)))
      dispatch(receiveReactivateSubscriptionError(e))
    }
  }
}

const isJson = string => {
  try {
    JSON.parse(string)
  } catch (error) {
    return false
  }
  return true
}

export function stripePayment(stripe, data) {
  return async function(dispatch, getState) {
    if (!data.subscription) {
      return dispatch(
        error(checkoutError(NOTIFICATION_MESSAGES.subscriptionMissing))
      )
    }

    const { promos, referral, userPreferences } = getState()
    const { activePromo } = promos
    const { trackingAllowed } = userPreferences
    dispatch(requestPayment())

    try {
      const res = await currentUserBillingApi.stripePayment({
        ...data,
        activePromo,
        referral,
      })

      if (res.status === 402 && isJson(res.data.error.userDescription)) {
        throw JSON.parse(res.data.error.userDescription)
      }

      if (res.status >= 400) {
        dispatch(error(checkoutError(res.data.error.userDescription)))
        return dispatch(receivePaymentError('Stripe', res.data.error))
      }

      dispatch(push('/payment-confirmation'))

      dispatch(removePromoCode())
      dispatch(removeSubscription())

      dispatch(receivePaymentSuccess('Stripe'))
      dispatch(success(successPaymentNotification(intl)))

      if (trackingAllowed && !process.env.IS_TEST) {
        const analytics = {
          price: data.subscription.price,
          currency: data.subscription.currencyId,
          promoName: data.promo?.promoName,
          promoCode: data.promo?.promoCode,
          paymentId: res.data.paymentId,
          subscriptionId: res.data.subscriptionId,
          subscriptionName: res.data.subscriptionName,
          subscriptionDuration: data.subscription.interval,
          email: getState().currentUser.user.email,
        }
        trackPurchaseEvent(analytics)
      }
    } catch (err) {
      if (err.requiresAction) {
        const {
          error: errorAction,
          paymentIntent,
        } = await stripe.confirmCardPayment(err.clientSecret)
        if (errorAction) {
          errorAction.userDescription = errorAction.message
          dispatch(error(checkoutError(errorAction.userDescription)))
          return dispatch(receivePaymentError('Stripe', errorAction))
        } else {
          // Confirm payment intent again
          return dispatch(
            stripePayment(stripe, {
              ...data,
              paymentIntent,
            })
          )
        }
      }
    }
  }
}

export function braintreePayment(data = {}) {
  return function(dispatch, getState) {
    if (!data.subscription) {
      return dispatch(
        error(checkoutError(NOTIFICATION_MESSAGES.subscriptionMissing))
      )
    }

    dispatch(requestPayment())
    const { currentUser, referral, userPreferences } = getState()
    const { user } = currentUser
    const { trackingAllowed } = userPreferences

    return currentUserBillingApi
      .braintreePayment({ ...data, referral })
      .then(res => {
        if (res.status >= 400) {
          dispatch(receivePaymentError('Braintree', res.data.error))
          return
        }

        dispatch(push('/payment-confirmation'))

        dispatch(receivePaymentSuccess('Braintree'))
        dispatch(success(successPaymentNotification(intl)))

        if (trackingAllowed && !process.env.IS_TEST) {
          const analytics = {
            price: data.subscription.price,
            currency: data.subscription.currencyId,
            promoName: data.promo?.promoName,
            promoCode: data.promo?.promoCode,
            paymentId: res.data.paymentId,
            subscriptionId: res.data.subscriptionId,
            subscriptionName: res.data.subscriptionName,
            subscriptionDuration: data.subscription.interval,
            email: user.email,
          }
          trackPurchaseEvent(analytics)
        }
      })
  }
}

function requestPaymentMethod() {
  return {
    type: REQUEST_PAYMENT_METHOD,
  }
}

function receivePaymentMethod(cardsOnFile) {
  return {
    type: RECEIVE_PAYMENT_METHOD,
    cardsOnFile,
  }
}

function receivePaymentMethodError(error) {
  return {
    type: ERROR_RECEIVING_PAYMENT_METHOD,
    error,
  }
}

export function fetchCardInfo() {
  return function(dispatch) {
    dispatch(requestPaymentMethod())
    return currentUserBillingApi.getCurrentUserPayment().then(res => {
      if (res.status >= 400) {
        dispatch(receivePaymentMethodError(res.data.error))
        return
      }
      dispatch(receivePaymentMethod(res.data))
      return
    })
  }
}

function requestUpdatePaymentMethod() {
  return {
    type: REQUEST_PAYMENT_METHOD_CHANGE,
  }
}

function receiveUpdatePaymentMethod() {
  return {
    type: RECEIVE_PAYMENT_METHOD_CHANGE_SUCCESS,
  }
}

function receiveUpdatePaymentMethodError(error) {
  return {
    type: RECEIVE_PAYMENT_METHOD_CHANGE_ERROR,
    error,
  }
}

export function updateCardInfo(_stripe, data = {}) {
  return async function(dispatch) {
    dispatch(requestUpdatePaymentMethod())
    const res = await currentUserBillingApi.updateCurrentUserPayment(
      data.gatewayToken
    )

    if (res.status >= 400) {
      dispatch(
        error({
          ...errorBilling(intl),
          message: res.data.error.userDescription,
        })
      )
      return dispatch(receiveUpdatePaymentMethodError(res.body.error))
    }
    dispatch(push('/account/billing'))
    dispatch(receiveUpdatePaymentMethod())
    dispatch(success(successCardChangeNotification(intl)))
  }
}

export function validateActiveSubscription() {
  return async function(dispatch) {
    dispatch(validatingSubscriptionStatus())
    await currentUserBillingApi.validateSubscriptionStatus()
    dispatch(validatedSubscriptionStatus())
  }
}
