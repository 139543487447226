export const TRACKLIST = {
  NEW_RELEASES: 'New Releases',
  ARTISTS: 'Artists',
  EXCLUSIVES: 'Exclusives',
  GLOBAL: 'Global',
  HOTBOX: 'Hotbox',
  PLAYLISTS: 'Playlists',
  REMIXERS: 'Remixers',
  GENRES: 'Genres',
  TOP_50: 'Top 50',
  RELATED: 'Track Details - Related',
  RELATED_NEW_STACKED_STYLE: 'Track Details - Recommended (Stacked Style)',
  SEARCH: 'Search',
  HISTORY: 'History',
  UPDATES: 'Updates',
  CRATE: 'Crate',
  STATISTICS: 'Statistics',
}
