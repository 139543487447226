import { createSelector } from 'reselect'
import {
  encodeDate,
  localizedPopoverMessages,
  POPOVER_MONTHS,
} from '../components/filters'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'

export const songInSongsSelector = createSelector(
  (_, props) => props.songId,
  state => state.songs.songsById,
  (songId, songsById) => songsById[songId]
)

export const trackInSongsSelector = createSelector(
  (_, props) => props.songId,
  (_, props) => props.trackId,
  state => state.songs.songsById,
  (songId, trackId, songsById) => {
    const song = songsById[songId]
    let track = null
    if (song?.types) {
      track = song.types.find(t => t.ttid == trackId)
    }
    return track
  }
)

export const songInCrateSelector = createSelector(
  (_, props) => props.songId,
  state => state.crate.items,
  (songId, items) => {
    const item = items.find(
      x => parseInt(x.track.record.rid) === parseInt(songId)
    )
    return item ? item.track.record : null
  }
)

export const trackInCrateSelector = createSelector(
  (_, props) => props.songId,
  (_, props) => props.trackId,
  state => state.crate.items,
  (songId, trackId, items) => {
    const item = items.find(
      x =>
        x.track.ttid == trackId &&
        parseInt(x.track.record.rid) === parseInt(songId)
    )
    return item ? { ...item.track, inCrate: true } : null
  }
)

export const songInPreviousDownloadsSelector = createSelector(
  (_, props) => props.songId,
  state => get(state, 'currentUser.previouslyDownloaded', []),
  (songId, items) => {
    const item = items.find(x => parseInt(x.record.rid) === parseInt(songId))
    return item ? item.record : null
  }
)

export const trackInPreviousDownloadsSelector = createSelector(
  (_, props) => props.songId,
  (_, props) => props.trackId,
  state => get(state, 'currentUser.previouslyDownloaded', []),
  (songId, trackId, items) => {
    return items.find(
      x => x.ttid == trackId && parseInt(x.record.rid) === parseInt(songId)
    )
  }
)

export const songInUpdatesSelector = createSelector(
  (_, props) => props.songId,
  state => get(state, 'updatedSongs.tracks', []),
  (songId, items) => {
    const item = items.find(x => parseInt(x.record.rid) === parseInt(songId))
    return item ? item.record : null
  }
)

export const trackInUpdatesSelector = createSelector(
  (_, props) => props.songId,
  (_, props) => props.trackId,
  state => get(state, 'updatedSongs.tracks', []),
  (songId, trackId, items) => {
    return items.find(
      x => x.ttid == trackId && parseInt(x.record.rid) === parseInt(songId)
    )
  }
)

export const songInArtistResultsSelector = createSelector(
  (_, props) => props.songId,
  state => get(state, 'artistSongs.songs', []),
  (songId, items) => items.find(x => parseInt(x.rid) === parseInt(songId))
)

export const trackInArtistResultsSelector = createSelector(
  (_, props) => props.songId,
  (_, props) => props.trackId,
  state => get(state, 'artistSongs.songs', []),
  (songId, trackId, items) => {
    const song = items.find(x => parseInt(x.rid) === parseInt(songId))
    let track = null
    if (song) {
      track = song.types.find(t => t.ttid == trackId)
    }
    return track
  }
)

export const songInSearchResultsSelector = createSelector(
  (_, props) => props.songId,
  state => get(state, 'searchSongs.songs', []),
  (songId, items) => items.find(x => parseInt(x.rid) === parseInt(songId))
)

export const trackInSearchResultsSelector = createSelector(
  (_, props) => props.songId,
  (_, props) => props.trackId,
  state => get(state, 'searchSongs.songs', []),
  (songId, trackId, items) => {
    const song = items.find(x => parseInt(x.rid) === parseInt(songId))
    let track = null
    if (song) {
      track = song.types.find(t => t.ttid == trackId)
    }
    return track
  }
)

export const songSelector = (state, { songId }) => {
  // Try to find the song in the songs reducer
  let song = songInSongsSelector(state, { songId })

  // If it's not there, look in the crate reducer
  if (!song) {
    song = songInCrateSelector(state, { songId })
  }

  // If it's also not there, look in currentUser.previousDownloaded
  if (!song) {
    song = songInPreviousDownloadsSelector(state, { songId })
  }

  // If it's also not there, look in updatedSongs.tracks
  if (!song) {
    song = songInUpdatesSelector(state, { songId })
  }

  // If it's also not there, look in searchSongs.songs
  if (!song) {
    song = songInSearchResultsSelector(state, { songId })
  }

  // If it's also not there, look in artistSongs.songs
  if (!song) {
    song = songInArtistResultsSelector(state, { songId })
  }

  return song
}

export const trackSelector = (state, { songId, trackId }) => {
  if (!songId && !trackId) {
    return null
  }

  // Try to find the track in the songs reducer
  let track = trackInSongsSelector(state, { songId, trackId })

  // If it's not there, look in the crate reducer
  if (!track) {
    track = trackInCrateSelector(state, { songId, trackId })
  }

  // If it's also not there, look in currentUser.previousDownloaded
  if (!track) {
    track = trackInPreviousDownloadsSelector(state, { songId, trackId })
  }

  // If it's also not there, look in updatedSongs.tracks
  if (!track) {
    track = trackInUpdatesSelector(state, { songId, trackId })
  }

  // If it's also not there, look in searchSongs.songs
  if (!track) {
    track = trackInSearchResultsSelector(state, { songId, trackId })
  }

  // If it's also not there, look in artistSongs.songs
  if (!track) {
    track = trackInArtistResultsSelector(state, { songId, trackId })
  }

  return track
}

export const currentSongSelector = createSelector(
  state => state,
  state => state.player.currentSongId,
  (state, songId) => songSelector(state, { songId })
)

export const completedSongSelector = createSelector(
  state => state.player.completedSongId,
  state => state.songs.songsById,
  (completedSongId, songsById) => songsById[completedSongId]
)

export const queuedSongsSelector = createSelector(
  state => state.player.queue,
  state => state.songs.songsById,
  (queue, songsById) =>
    queue.reduce((queuedSongs, song) => {
      if (songsById[song.id]) {
        queuedSongs.push(songsById[song.id])
      }
      return queuedSongs
    }, [])
)

export function groupByDate(songs, dateField = 'releasedate') {
  return reduce(
    groupBy(songs, song => song[dateField].replace(/T.*/, '')),
    (arr, songsArr) => {
      arr.push({
        [dateField]: songsArr[0][dateField],
        songs: songsArr,
      })
      return arr
    },
    []
  )
}

export const globalSongsSelectorGrouped = createSelector(
  state => state.globalSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) =>
    groupByDate(songIds.map(id => songsById[id]).filter(x => x))
)

export const globalSongsSelector = createSelector(
  state => state.globalSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) => songIds.map(id => songsById[id]).filter(x => x)
)

export const newSongsSelector = createSelector(
  state => state.newSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) =>
    groupByDate(songIds.map(id => songsById[id]).filter(x => x))
)

export const exclusivesSongsSelectorGrouped = createSelector(
  state => state.exclusivesSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) =>
    groupByDate(songIds.map(id => songsById[id]).filter(x => x))
)

export const exclusivesSongsSelector = createSelector(
  state => state.exclusivesSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) => songIds.map(id => songsById[id]).filter(x => x)
)

export const hotBoxSongsSelector = createSelector(
  state => state.hotBoxSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) => songIds.map(id => songsById[id]).filter(x => x)
)

export const topExclusivesSongsSelector = createSelector(
  state => state.exclusivesSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) => songIds.map(id => songsById[id]).filter(x => x)
)

export const topDownloadsSongsSelector = createSelector(
  state => state.topDownloadsSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) => songIds.map(id => songsById[id]).filter(x => x)
)

export const playlistsSelector = createSelector(
  state => state.playlistsSongs.playlists,
  state => state.songs.songsById,
  (playlists, songsById) =>
    playlists.map(playlist => ({
      ...playlist,
      songs: playlist.songIds.map(id => songsById[id]).filter(x => x),
    }))
)

const datesSelector = (dates, intl) => {
  const monthValues = {}
  dates.forEach(playlistDate => {
    const date = new Date()
    date.setFullYear(playlistDate.year, playlistDate.month - 1, 1)
    monthValues[encodeDate(date)] = `${intl.formatMessage(
      localizedPopoverMessages[POPOVER_MONTHS[date.getMonth()]]
    )}, ${date.getFullYear()}`
  })
  return monthValues
}

export const playlistDateSelector = (state, intl) =>
  datesSelector(state.playlistsData.dates, intl)

export const topDownloadsDateSelector = (state, intl) =>
  datesSelector(state.topDownloadsDates.dates, intl)

export const tagSongSelectorGrouped = createSelector(
  state => state.tagSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) =>
    groupByDate(songIds.map(id => songsById[id]).filter(x => x))
)

export const tagSongSelector = createSelector(
  state => state.tagSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) => songIds.map(id => songsById[id]).filter(x => x)
)

export const remixSongSelectorGrouped = createSelector(
  state => state.remixSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) =>
    groupByDate(songIds.map(id => songsById[id]).filter(x => x))
)

export const remixSongSelector = createSelector(
  state => state.remixSongs.songIds,
  state => state.songs.songsById,
  (songIds, songsById) => songIds.map(id => songsById[id]).filter(x => x)
)

export const trackInDownloadQueue = (state, { songId, trackId }) => {
  return !!get(state, `queue.queue.${songId}:${trackId}`)
}

export const tracksInDownloadQueue = (state, { songId, trackIds }) => {
  return trackIds.every(
    trackId => !!get(state, `queue.queue.${songId}:${trackId}`)
  )
}
