import {
  fetchWithHeaders,
  getQueryParams,
  parseResponse,
} from '../shared/utils'

export function getSubscriptions() {
  return fetchWithHeaders(`/subscriptions`, {
    method: 'GET',
  }).then(parseResponse)
}

export function fetchUserSubscription() {
  return fetchWithHeaders(`/subscriptions/user`, {
    method: 'GET',
  }).then(parseResponse)
}

export function changeUserSubscription(params = {}) {
  return fetchWithHeaders(`/subscriptions/user?${getQueryParams(params)}`, {
    method: 'PUT',
  }).then(parseResponse)
}
