import { Layout } from '../../layout'
import { ModuleLoader } from '../../module_loader/module_loader'
import React from 'react'
const Statistics = React.lazy(() => import('./statistics'))

const display = () => {
  return (
    <Layout fullLayout>
      <ModuleLoader>
        <Statistics />
      </ModuleLoader>
    </Layout>
  )
}

export { display as StatisticsDisplay }
