import { defineMessages } from 'react-intl'

export default defineMessages({
  browseTitle: {
    id: 'djcity.common.navigation.browse',
    defaultMessage: 'Record Pool',
  },
  moreTitle: {
    id: 'djcity.common.navigation.more',
    defaultMessage: 'More',
  },
  newsTitle: {
    id: 'djcity.common.navigation.news',
    defaultMessage: 'News',
  },
  haveAccount: {
    id: 'djcity.common.navigation.haveAccount',
    defaultMessage: 'Already have an account ?',
  },
  login: {
    id: 'djcity.common.navigation.login',
    defaultMessage: 'Login',
  },
  darkMode: {
    id: 'djcity.common.navigation.darkMode',
    defaultMessage: 'Dark Mode',
  },
  off: {
    id: 'djcity.common.navigation.off',
    defaultMessage: 'Off',
  },
  on: {
    id: 'djcity.common.navigation.on',
    defaultMessage: 'On',
  },
  home: {
    id: 'djcity.common.sidebar.home',
    defaultMessage: 'Home',
  },
  newReleases: {
    id: 'djcity.common.sidebar.newReleases',
    defaultMessage: 'New Releases',
  },
  mostPopular: {
    id: 'djcity.common.sidebar.mostPopular',
    defaultMessage: 'Most Popular',
  },
  exclusives: {
    id: 'djcity.common.sidebar.exclusives',
    defaultMessage: 'DJcity Exclusives',
  },
  playlists: {
    id: 'djcity.common.sidebar.playlists',
    defaultMessage: 'Playlists',
  },
  monthlyCharts: {
    id: 'djcity.common.sidebar.monthlyCharts',
    defaultMessage: 'Monthly Charts',
  },
  genres: {
    id: 'djcity.common.sidebar.genres',
    defaultMessage: 'Genres',
  },
  remixers: {
    id: 'djcity.common.sidebar.remixers',
    defaultMessage: 'Remixers',
  },
  yourRecommendations: {
    id: 'djcity.common.sidebar.yourRecommendations',
    defaultMessage: 'Your Recommendations',
  },
  trackUpdates: {
    id: 'djcity.common.sidebar.trackUpdates',
    defaultMessage: 'Track Updates',
  },
  globalSongs: {
    id: 'djcity.common.sidebar.global_songs',
    defaultMessage: 'Global',
  },
  faqUrl: {
    id: 'djcity.common.faq.url',
    defaultMessage: 'https://support.djcity.com/hc',
  },
})
